export interface DropdownOption {
    id: number;
    title: string;
    data: any;
}

export interface DropdownProps {
    title?: string;
    options: DropdownOption[];
    preselected?: number[];
    onChange?: (values: number[]) => void;
}

export interface DropdownState {
    opened: boolean;
    chosenValues: number[];
}

export function dataToDropdownOptions(data: any[]) {
    return [...data].map(d => {
        const option: DropdownOption = {
            id: d.id,
            title: d.name,
            data: d,
        }
        return option;
    })
}