import React from "react";
import "./style.scss";

interface ButtonProps {
    onClick?: Function;
    class?: string;
}

interface CommonButtonProps extends ButtonProps {
    type?: string;
    addStyle?: string;
}

class CommonButton extends React.Component<CommonButtonProps, {}> {
    clickHandler(...args: any) {
        if (this.props.onClick) {
            this.props.onClick(...args);
        }
    }

    render() {
        const clickHandler = this.props.onClick || (() => { });
        return (
            <div
                className={`common-button ${this.props.type || ""} ${this.props.addStyle || ""}`}
                onClick={(...args) => clickHandler(...args)}
            >
                {this.props.children}
            </div>
        );
    }
}

export class PrimaryButton extends React.Component<ButtonProps, {}> {
    render() {
        return (
            <CommonButton type="primary"
                onClick={this.props.onClick}
                addStyle={this.props.class}
            >
                {
                    this.props.children
                }
            </CommonButton>
        );
    }
}

export class Secondary1Button extends React.Component<ButtonProps, {}> {
    render() {
        return (
            <CommonButton
                type="secondary1"
                onClick={this.props.onClick}
                addStyle={this.props.class}
            >
                {
                    this.props.children
                }
            </CommonButton>
        );
    }
}

export class Secondary2Button extends React.Component<ButtonProps, {}> {
    render() {
        return (
            <CommonButton
                type="secondary2"
                onClick={this.props.onClick}
                addStyle={this.props.class}
            >
                {
                    this.props.children
                }
            </CommonButton>
        );
    }
}

