import { translations } from "../../../../../i18n";

export const labels = [
  {
    id: 0,
    content: "Резюме",
  },
  {
    id: 1,
    content: "Стратегия поиска работы",
  },
  {
    id: 2,
    content: "Анализ рынка",
  },
  {
    id: 3,
    content: "Самоопределение",
  },
  {
    id: 4,
    content: "Выгорание",
  },
  {
    id: 5,
    content: "Индивидуальный план развития",
  },
  {
    id: 6,
    content: "Профориентация подростков",
  },
  {
    id: 7,
    content: "Релокация внутри страны",
  },
  {
    id: 8,
    content: "Релокация заграницу",
  },
  {
    id: 9,
    content: "Профориентация взрослых",
  },
  {
    id: 10,
    content: "Переговоры о заработной плате",
  },
  {
    id: 11,
    content: "Собеседование",
  },
  {
    id: 12,
    content: "Полный список запросов",
  },
];

export const indicators = [
  {
    id: 0,
    value: translations.howWorks.indicatorValue1,
    title: translations.howWorks.indicatorDesc1,
  },
  {
    id: 1,
    value: translations.howWorks.indicatorValue2,
    title: translations.howWorks.indicatorDesc2,
  },
  {
    id: 2,
    value: translations.howWorks.indicatorValue3,
    title: translations.howWorks.indicatorDesc3,
  },
];

export const road = [
  {
    id: 0,
    title: translations.howWorks.step1,
    description: translations.howWorks.stepDesc1,
  },
  {
    id: 1,
    title: translations.howWorks.step2,
    description: translations.howWorks.stepDesc2,
  },
  {
    id: 2,
    title: translations.howWorks.step3,
    description: translations.howWorks.stepDesc3,
  }
];
