import React from "react";
import "./style.scss";
import Plus from "../../../../Media/Icons/plus.svg";
import { Heading3 } from "../../../Headings";

interface PopupProps {
    active: boolean;
    onClick: Function;
    title?: any;
}

class Popup extends React.Component<PopupProps, {}> {
    constructor(props: PopupProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                {this.props.active && (
                    <><div className="common-popup-children">
                        <div
                            className="common-popup-close-button"
                            onClick={() => this.props.onClick()}
                        >
                            <img src={Plus} alt="-"></img>
                        </div>
                        <Heading3>
                            <div className="common-popup-title">
                                {this.props.title}
                            </div>
                        </Heading3>
                        {this.props.children}
                    </div>
                        <div
                            className="common-popup-background"
                            onClick={() => this.props.onClick()}
                        ></div>
                    </>
                )}
            </>
        );
    }
}

export default Popup;
