export enum MenuType {
  Plain = "Plain",
  Box = "Box",
  Borderder = "Borderer",
}

export enum Path {
  Root = "/",
  Catalog = "/specialists",
  Specialist = "/specialists/:id",
  Auth = "/auth",
  SuccessRegistration = "/verification/success",
  ErrorRegistration = "/verification/error",
  Error = "/error",
}
