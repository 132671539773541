import React from "react";
import "./style.scss";

interface HeadingProps {
    
}

export class Heading1 extends React.Component<HeadingProps, {}> {
    render() {
        return (
            <div className="heading h1">{this.props.children}</div>
        );
    }
};

export class Heading2 extends React.Component<HeadingProps, {}> {
    render() {
        return (
            <div className="heading h2">{this.props.children}</div>
        );
    }
};

export class Heading3 extends React.Component<HeadingProps, {}> {
    render() {
        return (
            <div className="heading h3">{this.props.children}</div>
        );
    }
};

export class Heading4 extends React.Component<HeadingProps, {}> {
    render() {
        return (
            <div className="heading h4">{this.props.children}</div>
        );
    }
};
