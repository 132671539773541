import React from "react";
import { TopMenu } from "../../Components/Layout/Common/TopMenu";
import {MenuType} from "../../Components/Layout/Common/TopMenu/types";
import { Footer } from "../../Components/Layout/Common/Footer";

export const Agreement = () => {
  return (
    <>
     <div className="wrapper" style={{marginBottom: "50px"}}>
      <h1>Пользовательское соглашение</h1>

<h2>Общие положения настоящего Соглашения
</h2>
<ul>
<li>Настоящее Пользовательское соглашение (далее – Соглашение) относится к Платформе, расположенной по адресу http://adabstar.ru. и заключается между «ИП Кузнецова Александра Дмитриевна» (далее – «Администрация Платформы») ОГРНИП: 322470400104375, ИНН: 110214124443 и лицом, успешно прошедшим регистрацию на Платформе http://adabstar.ru.  и начавшим использование Платформы ADABSTAR.
</li>
<li>Настоящее Соглашение регулирует отношения между Администрацией Платформы и Пользователем Платформы. 
</li>
<li>Администрация платформы оставляет за собой право в любое время изменять, добавлять или удалять пункты настоящего Соглашения без уведомления Пользователя.
</li>
<li>Продолжение использования Платформы Пользователем означает принятие Соглашения и изменений, внесенных в настоящее Соглашение.
</li>
<li>Пользователь несет персональную ответственность за проверку настоящего Соглашения на наличие изменений в нем.
</li>
<li>Настоящее соглашение не является возмездным договором оказания услуг, передачей прав на использование Платформы ADABSTAR, лицензионным договором или иным договором, предполагающим оплату услуг Администрации Платформы, а представляет собой свод общих правил и требований, регулирующих использование Сервиса Пользователем.
</li>
<li>Принимая условия настоящего Соглашения и создавая Личный Кабинет на Платформе ADABSTAR, Вы соглашаетесь выполнять и соблюдать все правила и требования по использованию Платформы ADABSTAR, соглашаетесь с правилами и ценами оплаты услуг третьих лиц и правилами осуществления платежей в рамках Платформы, а Администрация Платформы предоставляет Вам доступ к Сервису на условиях настоящего Соглашения.
</li>
<li>Настоящее Пользовательское соглашение регулирует ваш доступ и использование Платформы adabstar.ru., ее функций, услуг, технологий и программного обеспечения, опубликованных на ней, предоставляемых Администрацией Платформы. 
</li>
<li>В соответствии с условиями, изложенными в настоящем документе, Платформа ADABSTAR может по своему усмотрению вносить изменения в настоящее Пользовательское соглашение и любые другие соглашения, входящие в состав Пользовательского соглашения, в любое время, опубликовав измененную версию на Платформе. Любые изменения Пользовательского соглашения вступают в силу с указанной даты последнего обновления («Дата последнего обновления»).
</li>
<li>Ни при каких условиях, Пользователю не предоставляются какие-либо имущественные интеллектуальные права в отношении Платформы, его программного обеспечения.
</li>
<li>Администрация Платформы гарантирует, что обладает всеми правами, необходимыми для предоставления Пользователю возможности использования Платформы ADABSTAR по настоящему Соглашению.
</li>
<li>Настоящее Соглашение вступает в силу в момент принятия его условий Пользователем, и продолжает действовать в полной силе до момента прекращения использования Платформы ADABSTAR Пользователем.
</li>
<li>Администрация Платформы вправе прекратить действие настоящего Соглашения в одностороннем порядке в любой момент времени, по единоличному усмотрению, в том числе в случае нарушения Пользователем любого положения Соглашения. 
</li>

</ul>
<h2>Термины и определения
</h2>

<ul>
<li>Для целей настоящего Пользовательского соглашения (далее — «Соглашение») нижеуказанные термины имеют следующие значения:
</li>
<li>Платформа — Интернет-ресурс, расположенный в сети Интернет в домене adabstar.ru. Под Платформой, в зависимости от контекста, понимается также программное обеспечение Платформы, дизайн (графическое оформление) Платформы, База данных, любой раздел (подраздел) Платформы (объявления, Форум и др.), а также Информация, размещенная на Платформе Администрацией Платформы и Пользователями.
</li>
<li>Администрация Платформы — «ИП Кузнецова Александра Дмитриевна» (ОГРНИП: 322470400104375, ИНН: 110214124443), обладает всеми необходимыми правами в отношении Платформы, включая право на использование доменного имени Платформы и осуществляющее администрирование Платформы, а также администрацией Платформы являются уполномоченные сотрудники управления Платформой, действующие от имени «ИП Кузнецова Александра Дмитриевна», которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
</li>
<li>Регистрация — процесс создания Учетной записи Пользователя на Платформе.
</li>
<li>Авторизация — процесс идентификации зарегистрированного Пользователя путём ввода логина Пользователя/либо иных данных и пароля, для получения доступа к Учетной записи Пользователя, закрытым разделам и расширенному функционалу Платформы.
</li>
<li>Пользователь — лицо, прошедшее Процедуру регистрации. Для целей настоящего Соглашения под Пользователем понимается также лицо, которое не прошло Процедуру регистрации, но осуществляет доступ к Платформе и/или использует и/или использовало его.
</li>
<li>Партнер — юридическое или физическое лицо, а именно индивидуальный предприниматель или самозанятый гражданин, осуществляющий на Платформе коммерческую деятельность на основании соответствующего договора с Администрацией Платформы и зарегистрировавшееся в качестве Партнера в установленном порядке, имеющее в определенных случаях доступ к дополнительному функционалу Платформы. В отношении Партнера действуют все правила настоящего Соглашения, установленные для иных Пользователей.
</li>
<li>Учетная запись Пользователя / Личный кабинет Партнера — специальный подраздел Платформы, позволяющий Пользователю получить доступ к определенному функционалу Платформы, а также получить доступ к Персональной информации Пользователя, размещенной в рамках Учетной записи, и иной Информации. Партнер в определенных случаях получает доступ к отдельной Учетной записи, обладающей дополнительным функционалом (Личный кабинет Партнера).
</li>
<li>Персональная информация Пользователя — любая информация, которую Пользователь предоставляет о себе самостоятельно при регистрации (создании Учетной записи Пользователя) или в процессе использования Платформы, включая персональные данные Пользователя, а также информация, которая автоматически передается Администрации Платформы в процессе использования Платформы с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, информация из cookie, информация о браузере Пользователя, геолокационные данные устройств Пользователя, данные о действиях Пользователя на Платформе, а также иные данные о Пользователе в соответствии с Политикой конфиденциальности.
</li>
<li>Информация — любая информация, размещаемая (размещенная) Пользователем или Администрацией Платформы на Платформе, в том числе: информация об оказываемых услугах, рекламные сообщения, Персональная информация Пользователя, ссылки на другие Платформы, любые текстовые сообщения, фотографии (изображения), аудио- и/или видео-произведения, компьютерные программы, дизайн Платформы и т.п.
</li>
<li>База данных — совокупность Информации, размещенной на Платформе. Вся Информация, размещенная на Платформе, а также подбор, группировка и расположение Информации, является интеллектуальной собственностью Администрации Платформы и (или) иных правообладателей.
</li>
<li>Модератор — Пользователь, имеющий по сравнению с другими Пользователями на Форуме более широкие права, предоставленные Администрацией Платформы в порядке, установленном настоящим Соглашением.
</li>
<li>Любые термины и понятия, использующиеся в Пользовательском соглашении и не нашедшие отражения в разделе «Термины и определения», будут толковаться в соответствии со смыслом, вытекающим из текста Пользовательского соглашения. В случае возникновения любых разногласий относительно толкования термина и/или понятия, используемого в Пользовательском соглашении, применяться будет то толкование, которое будет определено Администрацией Платформы.
</li>

</ul>
<h2>Предмет соглашения 
</h2>

<ul>
<li>Настоящее Пользовательское Соглашение (далее — Соглашение) устанавливает правила и условия пользования Платформы и размещения на ней Информации и представляет собой договор между Пользователем и Администрацией Платформы, который заменяет собой все предыдущие соглашения между Пользователем и Администрацией Платформы.
</li>
<li>Доступ к Платформе, использование Платформы и/или совершение любых иных действий на Платформе Пользователем означает, что Пользователь принимает и обязуется соблюдать все условия настоящего Соглашения без оговорок и исключений. Регистрация Пользователя на Платформе возможна только при дополнительном подтверждении Пользователем принятия настоящего Соглашения. Если Пользователь не согласен с условиями Соглашения, то он должен немедленно прекратить использование Платформы.
</li>

</ul>
<h2>Регистрация на Платформе
</h2>

<ul>

<li>Для того чтобы получить доступ ко всем возможностям Платформы, лицо должно пройти процедуру регистрации на Платформе, совершив ряд последовательных действий по заполнению регистрационной формы, в результате которых для Пользователя будет создана уникальная Учетная запись.
</li>
<li>Любой Пользователь вправе иметь только одну Учетную запись на Платформе. В случае нарушения Пользователем данного правила Администрация Платформы может заблокировать Учетные записи Пользователя (включая Личный кабинет (ы) Партнера), или ограничить функциональные возможности использования Пользователем Платформы и/или условия такого использования, и/или удалить Информацию Пользователя.
</li>
<li>Для регистрации в качестве Партнера и получения доступа к Личному кабинету Партнера, Пользователю, прошедшему процедуру регистрации на Платформе, необходимо выполнить ряд определённых действий, направленных на регистрацию и создание учетной записи Партнера.
</li>
<li>Пользователь соглашается предоставлять правдивую, точную и полную информацию о себе в регистрационной форме и поддерживать эту информацию в актуальном состоянии. Если эта информация изменится, Пользователь обязуется изменить ее как можно скорее. При этом Пользователь соглашается, что Администрация Платформы вправе, но в общем случае не обязана, проверять достоверность информации, предоставленной Пользователем о себе, или осуществлять контроль за дееспособностью Пользователя.
</li>
<li>Администрация Платформы вправе ограничить доступ Пользователя ко всем или некоторым возможностям Платформы, удалить Информацию Пользователя, в случае если Пользователь предоставил неверную информацию о себе, или у Администрации Платформы будут основания полагать, что предоставленная Пользователем информация о себе недостоверна, неполна, неточна, нарушает условия настоящего Соглашения или Пользователь использует чужую информацию.
</li>
<li>Администрация Платформы оставляет за собой право в любой момент потребовать от Пользователя подтверждения информации, предоставленной Пользователем о себе, и запросить, в связи с этим подтверждающие документы (в частности — документы, удостоверяющие личность). В случае непредоставления указанных документов или если информация Пользователя, указанная в предоставленных им документах, не соответствуют информации, указанной Пользователем о себе на Платформе, а также в случае, когда информация, указанная Пользователем о себе на Платформе, не позволяет идентифицировать Пользователя, Администрация Платформы по своему усмотрению может принять меры, предусмотренные п. 4.5. Соглашения.
</li>
</ul>
<h2>Авторизация на Платформе
</h2>
<ul>

<li>Действия, совершенные Пользователем на Платформе под своей Учетной записью, вход в которую был осуществлен посредством адреса электронной почты, будет считаться совершенными от лица Пользователя как владельца Учетной записи. Пользователь самостоятельно несет ответственность за все действия, совершенные им на Платформе, а также за все действия, совершенные на Платформе любыми иными лицами с использованием Учетной записи Пользователя.
</li>
<li>Пользователь обязуется не разглашать, не передавать третьим лицам свои логин и пароль, а также предпринять все необходимые усилия для того, чтобы его логин и пароль не были доступны третьим лицам. Пользователь самостоятельно несет ответственность за сохранность своего пароля, а также за все последствия, которые могут возникнуть в результате его несанкционированного и/или иного использования. Пользователь обязан немедленно уведомить Администрацию Платформы о любом случае несанкционированной (не разрешенной Пользователем) авторизации на Платформе с использованием логина и пароля и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своего пароля.
</li>
</ul>
<h2>Персональная информация Пользователя
</h2>

<ul>

<li>Персональная информация, полученная Администрацией Платформы при использовании Пользователем Платформы, обрабатывается на условиях Политики конфиденциальности, размещенной по адресу: ___  с учетом положений настоящего Соглашения.
</li>
<li>Администрация Платформы обрабатывает Персональную информацию Пользователя, указанную им на Платформе, переданную Администрации Платформы на основании п. 4.6. Соглашения. 
</li>
<li>Пользователь соглашается с тем, что его Персональная информация, содержащаяся в Учетной записи Пользователя/Личном кабинете Партнера является общедоступной, и любой Пользователь Платформы может ознакомиться с ней, за исключением Персональной информации, доступ к которой Пользователь может ограничить при наличии соответствующего функционала Платформы.
</li>
<li>Указанная Пользователем Информация (включая Персональную информацию), опубликованная Пользователем и доступная на Платформе, подлежит отображению для неограниченного круга лиц исключительно для целей предоставления функциональности Платформы. Информация, размещенная Пользователем на Платформе не предназначена для ее сбора и дальнейшего распространения третьими лицами без получения у Пользователя отдельного согласия.
</li>
<li>Персональная информация Пользователя обрабатывается в целях предоставления функциональности Платформы, осуществления обратной связи по вопросам, связанным с использованием Платформы, обеспечения соблюдения требований Соглашения, а также законных интересов Администрации Платформы, и в иных целях, указанных в настоящем Соглашении и Политике конфиденциальности.
</li>
<li>Пользователь обязуется не размещать на Платформе персональную информацию других лиц, а также не использовать персональную информацию других Пользователей, каким-либо образом, не соответствующим требованиям законодательства Российской Федерации, в целях извлечения выгоды и в любых иных целях, не соответствующих целям создания Платформы и настоящему Соглашению.
</li>
<li>Пользователь может в любой момент изменить (обновить, дополнить) либо удалить предоставленную им Персональную информацию или её часть при наличии соответствующего функционала на Платформе.
</li>
<li>Присоединяясь к настоящему Соглашению и оставляя свои данные на Платформе путем заполнения полей при регистрации на страницах Платформы, оформления заявки на услугу или в любом другом разделе Платформы, Пользователь:
</li>
<li>признает и подтверждает, что он внимательно и в полном объеме ознакомился с настоящим Соглашением и содержащимися в нем условиями обработки его персональных данных, указываемых им в соответствующих полях при регистрации на Платформе и дальнейшем его использовании;
</li>
<li>признает и подтверждает, что все положения настоящего Соглашения и условия обработки его персональных данных ему понятны;
</li>
<li>дает согласие на обработку Платформой предоставляемых персональных данных в целях регистрации Пользователя на Платформе, получения доступа к Платформе и дополнительному функционалу Платформы;
</li>
<li>соглашается получать от Платформы информационные рассылки на электронный адрес, указанный при работе с Платформой;
</li>
<li>выражает согласие с условиями обработки персональных данных в соответствии с требованиями Федерального закона от 27.07.2006 г. №152-ФЗ «О персональных данных», Политики в отношении обработки персональных данных без каких-либо оговорок и ограничений.
</li>
<li>Пользователь, предоставляет Платформе право осуществлять следующие действия (операции) с персональными данными:
</li>
<li>сбор и накопление;
</li>
<li>хранение в течение установленных нормативными документами сроков хранения отчетности, но не менее трех лет, с момента даты прекращения пользования услуг Платформы Пользователем;
</li>
<li>уточнение (обновление, изменение);
</li>
<li>использование в целях регистрации Пользователя на Платформе и в других целях для более комфортного использования Платформы Пользователем;
</li>
<li>уничтожение;
</li>
<li>передача данных третьим лицам (Партнерам Платформы) в целях возможного распространения информационных материалов партнеров и другой прямой маркетинговой информации;
</li>
<li>передача по требованию суда, в т.ч. третьим лицам, с соблюдением мер, обеспечивающих защиту персональных данных от несанкционированного доступа.
</li>
</ul>
<h2>Размещение на Платформе Информации
</h2>

<ul>

<li>Размещая Информацию на Платформе, Пользователь/Партнер гарантирует, что обладает всеми необходимыми правами для размещения Информации на Платформе. Если у Пользователя/Партнера нет надлежащих прав для размещения на Платформе какой-либо Информации, то Пользователь/Партнер обязуется не размещать такую Информацию.
</li>
<li>Пользователь/Партнер обязуется при размещении Информации на Платформе:
</li>
<li>не размещать на Платформе и не направлять куда-либо через/посредством Платформы следующую Информацию:
</li>
<li>клеветническую, оскорбительную, порочащую/унижающую честь и/или достоинство третьих лиц, содержащую угрозы;
</li>
<li>имеющую единственную цель причинить вред третьим лицам;
</li>
<li>нарушающую права граждан на частную жизнь и публичный порядок;
</li>
<li>пропагандирующую дискриминацию людей по расовому, этническому, половому, религиозному признакам, социальному статусу, сексуальной ориентации или иным признакам;
</li>
<li>содержащую экстремистские материалы, способствующую и/или призывающую к изменению конституционного строя, развязыванию войны, религиозной, расовой или межнациональной розни, содержащую попытки разжигания вражды или призывы к насилию;
</li>
<li>содержащую порнографические материалы или иные оскорбляющие нравственность материалы;
</li>
<li>оскорбляющую религиозные чувства граждан;
</li>
<li>информацию «заказного» характера, т.е. Информацию, созданную в чьих-либо интересах, не соответствующих целям создания Платформы;
</li>
<li>не публиковать Информацию на Платформе, минуя порядок размещения Информации, установленный Администрацией Платформы;
</li>
<li>не распространять на Платформе и/или через Платформу компьютерные вирусы или другие компьютерные коды, файлы или программы, предназначенные для нарушения, модификации, блокирования уничтожения либо ограничения функциональности любого компьютерного или телекоммуникационного оборудования, или программы для осуществления несанкционированного доступа, а также серийные номера к коммерческим программным продуктам и программы для их генерации, логины, пароли и прочие средства для получения несанкционированного доступа к Платформе, а также к платным ресурсам в сети Интернет;
</li>
<li>не размещать или иным способом не использовать на Платформе Информацию, охраняемую законодательством об интеллектуальной собственности (в том числе, но не ограничиваясь, которая затрагивает какой-либо патент, товарный знак, авторские и/или смежные с ними права), и иную охраняемую законом Информацию без соответствующих на то прав и разрешений от правообладателя такой Информации;
</li>
<li>не размещать на Платформе Информацию коммерческого характера за исключением мест и сервисов, специально предназначенных для этого с разрешения Администрации Платформы;
</li>
<li>не распространять на Платформе и/или через Платформа спам, «письма счастья», системы интернет заработка, схемы «пирамид», многоуровнего маркетинга, e-mail-бизнесов;
</li>
<li>не регистрироваться и не совершать действия на Платформе от имени другого реально существующего или существовавшего лица, не указывать персональную информацию третьих лиц, не применять любые формы и способы незаконного представительства третьих лиц;
</li>
<li>не размещать Информацию, не относимую к тематике и целям предоставления Платформы, оказываемых им услуг и/или тематике его разделов, в том числе не размещать в каталоге услуг Информацию, не связанную напрямую с описанием характеристик оказания услуг, в том числе касающуюся каких-либо третьих лиц, а также Информацию шуточного, глумливого, провокационного, личного, пропагандистского характера и т.д.;
</li>
<li>Администрация Платформы предоставляет Пользователю/Партнеру возможность размещать Информацию на Платформе и пользоваться сервисами Платформы исключительно в рамках предоставленного функционала Платформы. Пользователь соглашается с тем, что использование в других сервисах или на иных Платформах Информации, размещенной на Платформе, допускается только с предварительного разрешения Администрации Платформы.
</li>
<li>Любая Информация, размещаемая на Платформе Пользователем/Партнером, соответственно, принадлежит такому Пользователю/Партнеру или соответствующему третьему лицу.
</li>
<li>Администрация Платформы не несет никакой ответственности за достоверность и правомерность Информации, размещенной Пользователями/Партнерами на Платформе.
</li>
<li>Администрация Платформы может (но не обязана) внести изменения в содержание услуг Партнеров, в случае наличия очевидных ошибок и неточностей в размещенной Партнером информации. При несогласии с внесенными изменениями Партнер обязан незамедлительно обратиться к Администрации Платформы с пояснением и обоснованием своей позиции.
</li>
<li>Администрация Платформы не проверяет и не имеет технической и фактической возможности проверять всю Информацию, размещаемую Пользователем/Партнером на Платформе, на предмет ее соответствия требованиям законодательства Российской Федерации и положениям настоящего Соглашения, поскольку подобная проверка сделает невозможным нормальное функционирование Платформы. Однако Администрация Платформы может это делать в любое время по своему усмотрению в том числе в порядке, предусмотренном п. 4.6. настоящего Соглашения.
</li>
<li>В случае возникновения спорной ситуации бремя доказывания того, что размещаемая Пользователем/Партнером Информация не нарушает чьих-либо прав, лежит на таком Пользователе/Партнером.
</li>
<li>Администрация Платформы может по своему усмотрению отказать в размещении, удалить и/или отредактировать любую Информацию, которую Пользователь/Партнер собирается разместить/разместил на Платформе, в том числе без объяснения причин.
</li>
<li>В случаях, предусмотренных применимым законодательством, Администрация Платформы рассматривает Информацию, размещенную Пользователем/Партнером на Платформе, к которой Пользователь/Партнер ограничил доступ для третьих лиц с использованием функционала Платформы, как коммерческую тайну Пользователя/Партнера. Администрация Платформы обязуется не передавать такую Информацию третьим лицам (за исключением случаев передачи такой Информации между аффилированными с Администрацией Платформы лицами или в рамках установленной действующим законодательством процедуры). 
</li>
<li>Размещение отдельных видов Информации (отзывов об услугах) дополнительно регулируется соответствующими разделами настоящего Соглашения, а также иными документами, с которыми Пользователь соглашается при размещении соответствующей Информации.
</li>
</ul>
<h2>Использование Платформы
</h2>

<ul>

<li>Использование Платформы Пользователем/Партнерам допускается только в соответствии с настоящим Соглашением и исключительно способами, предусмотренными техническими возможностями и интерфейсом Платформы. 
</li>
<li>Пользователь/Партнер обязуется при использовании Платформы:
</li>
<li>не нарушать нормальную работу Платформы;
</li>
<li>не распространять и/или не использовать какие-либо компьютерные программы, роботы или другие автоматические алгоритмы и методы, направленные на незаконные: «выкачивание» (сбор), передачу, копирование, блокирование, модификацию, уничтожение Информации и Базы данных, а также направленные на обход ограничений, установленных Администрацией Платформы в настройках Платформы;
</li>
<li>не использовать контактные данные Пользователей/Партнеров для проведения незаконных рассылок без согласия адресата;
</li>
<li>не совершать посредством Платформы действия, направленные на вымогательство или получение денег, независимо от предлога, от других Пользователей и/или третьих лиц;
</li>
<li>не содействовать в совершении и/или не совершать с использованием Платформы иные противоправные, противозаконные действия, противоречащие требованиям законодательства Российской Федерации и/или настоящего Соглашения;
</li>
<li>без предварительного согласования с Администрацией Платформы не использовать Платформу в целях распространения рекламы или иного привлечения внимания Пользователей к каким-либо товарам, работам, услугам, Платформе, сервисам, средствам индивидуализации и иным объектам, и/или в целях предложения Пользователям каких-либо товаров, работ, услуг, доступа к Платформам, сервисам и прочим объектам, и/или в целях осуществления какого-либо иного взаимодействия с Пользователями, помимо оказания консультационных услуг.
</li>
<li>Без письменного разрешения Администрации Платформы Пользователь/Партнер обязуется не использовать автоматические программы для получения доступа к Платформе в целях извлечения, сбора, переработки, копирования, и/или последующего распространения Информации, представленной на Платформе и/или в Базе данных.
</li>
<li>Пользователи обязуются использовать Информацию только в личных некоммерческих целях, за исключением случаев, когда разрешение на иное использование дано Администрацией Платформы и/или соответствующими правообладателями.
</li>
</ul>
<h2>Функционирование Платформы и ответственность при его использовании
</h2>

<ul>

<li>Администрация Платформы не гарантирует, что Информация, размещенная на Платформе, будет доступна в любое время. Администрация Платформы не несет ответственности за сбои и задержки в работе Платформы, а также за возможные последствия таких сбоев и задержек.
</li>
<li>Администрация Платформы не несет ответственности за возможную утечку Информации из Базы данных Платформы по любым причинам, в том числе в результате несанкционированных действий третьих лиц.
</li>
<li>Администрация Платформы не несет ответственности за наличие на Платформе вирусов, а также за возможные последствия заражения компьютера Пользователя/Партнера вирусами или попадание на компьютер Пользователя/Партнер иных вредоносных программ.
</li>
<li>Администрация Платформы не отвечает, не возмещает и не несет ответственности за любые убытки, включая упущенную выгоду, моральный и иной вред, причиненные Пользователю/Партнеру или третьим лицам в результате использования ими Платформы или в связи с функционированием Платформы, в том числе, за убытки, связанные с принятием каких-либо решений и действий, основанных на Информации, размещенной на Платформе.
</li>
<li>Ссылка на любую Платформу, продукт, услугу, любую информацию, размещенная на Платформа Пользователем/Партнером, не является одобрением или рекомендацией данных продуктов (услуг, информации) со стороны Администрации Платформы.
</li>
<li>Пользователь/Партнер самостоятельно возмещает любые убытки (в том числе упущенную выгоду, моральный и иной вред), возникшие у других Пользователей/Партнеров, третьих лиц или Администрации Платформы вследствие нарушения Пользователем/Партнером настоящего Соглашения, в том числе вследствие размещения на Платформе Информации, а также вследствие совершения иных действий, связанных с использованием Платформы.
</li>
<li>Пользователь/Партнер обязуется своими силами и за свой счет разрешать все споры и урегулировать все претензии и иски третьих лиц, предписания и требования уполномоченных государственных органов власти, поступившие как в адрес Пользователя/Партнера, так и в адрес Администрации Платформы в связи с нарушением Пользователем/Партнером настоящего Соглашения или в связи с совершением Пользователем/Партнером иных противоправных действий при использовании Платформы, а также возместить все убытки и расходы, понесенные Администрацией Платформы в связи с такими претензиями и исками.
</li>
<li>На страницах с указанием услуг может размещаться дополнительная информации об услугах, предоставляемая Партнерами и/или их Специалистами, включая информацию о запросах, для которых оказываются услуги, специализацию работы Партнера, предоставляемых услуг, его направлений работы, способов связи, страны работы, рабочего времени, а также уровня владения языками, для оказания услуг. Администрация Платформы не несет ответственности за любую потерю, расходы, убытки или ущерб, связанные с действиями, основанными на такой информации.
</li>
<li>Администрация Платформы может оказывать Пользователю содействие в осуществлении подбора услуг на основании полученной от Пользователя информации и заданных им параметров. Взаимодействие с Пользователем может осуществляться через службу поддержки Платформы в ходе электронной переписки с Пользователем, либо иным другим способом взаимодействия. 
</li>
<li>На Платформе Пользователю и/или Партнеру может быть доступна функциональная возможность направления и обмена сообщениями с другими Пользователями и/или Партнерами Платформы.
</li>
</ul>

<h2>Оплата услуг
</h2>

<ul>

<li>Внесение стоимости за выбранные Пользователем услуги вне зависимости от выбранных Пользователем условий производится Пользователем путем безналичной оплаты, например, с банковской карты, данные которой указываются Пользователем при оплате услуг.
</li>
<li>Данные банковской карты могут быть привязаны к Платформе-партнеру в момент перехода к оплате выбранной единицы Услуг. При этом Пользователь указывает следующие данные:
</li>

<ul>
<li>Номер банковской карты;
</li>
<li>Срок окончания действия банковской карты
</li>
<li>Защитный код (CVV/CVC)
</li>
</ul>
<li>Платформа и Администрация Платформы не имеет доступа к указанным Пользователем данным банковской карты и не несет ответственности за сохранность и конфиденциальность передаваемых данных при проведении безналичной оплаты. Безналичная оплата осуществляется с участием уполномоченного оператора по приему платежей, оператора электронных денежных средств или иного участника расчетов и регулируется правилами международных платежных систем, банков (в том числе банка-эмитента Привязанной карты) и других участников расчетов.
</li>
<li>При указании своих данных использовании Привязанной карты Пользователь подтверждает и гарантирует указание им достоверной и полной информации о действительной банковской карте, выданной на его имя, соблюдение им правил международных платежных систем и требований банка-эмитента, выпустившего Привязанную карту, в том числе в отношении порядка проведения безналичных расчетов.
</li>
<li>Пользователь понимает и соглашается, что все действия, совершенные в рамках Платформы через его учетную запись, в том числе по безналичной оплате с использованием Привязанной карты, считаются совершенными Пользователем.
</li>
</ul>
<h2>Ответственность
</h2>

<ul>

<li>Администрация Платформы не несет ответственности за:
</li>
<li>Задержки или сбои в процессе совершения операции, возникшие вследствие непреодолимой силы, а также любого случая неполадок в телекоммуникационных, компьютерных, электрических и иных смежных системах;
</li>
<li>Действия систем переводов, банков, платежных систем и за задержки, связанные с их работой;
</li>
<li>Надлежащее функционирование Платформы, в случае, если Пользователь/Партнер не имеет необходимых технических средств для его использования, а также не несет никаких обязательств по обеспечению пользователей такими средствами.
</li>
</ul>
<h2>Нарушение условий пользовательского соглашения 
</h2>

<ul>

<li>Администрация Платформы вправе раскрыть любую собранную о Пользователе/Партнере данной Платформы информацию, если раскрытие необходимо в связи с расследованием или жалобой в отношении неправомерного использования Платформы либо для установления (идентификации) Пользователя/Партнера, который может нарушать или вмешиваться в права Администрации Платформы или в права других Пользователей/Партнеров Платформы.
</li>
<li>Администрация Платформы имеет право раскрыть любую информацию о Пользователе/Партнере, которую посчитает необходимой для выполнения положений действующего законодательства или судебных решений, обеспечения выполнения условий настоящего Соглашения, защиты прав или безопасности.
</li>
<li>Администрация Платформы вправе без предварительного уведомления Пользователя/Партнера прекратить и (или) заблокировать доступ к Платформе, если Пользователь/Партнер нарушил настоящее Соглашение или содержащиеся в иных документах условия пользования Платформы, а также в случае прекращения действия Платформы либо по причине технической неполадки или проблемы.
</li>
<li>Администрация Платформы не несет ответственности перед Пользователем/Партнером или третьими лицами за прекращение доступа к Платформе в случае нарушения Пользователем/Партнером любого положения настоящего Соглашения или иного документа, содержащего условия пользования Платформы.
</li>
</ul>
<h2>Разрешение споров 
</h2>

<ul>

<li>В случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения обязательным условием до обращения в суд является предъявление претензии (письменного предложения о добровольном урегулировании спора).
</li>
<li>Получатель претензии в течение 30 календарных дней со дня ее получения, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.
</li>
<li>При невозможности разрешить спор в добровольном порядке любая из Сторон вправе обратиться в суд за защитой своих прав, которые предоставлены им действующим законодательством Российской Федерации.
</li>
<li>Любой иск в отношении условий использования Платформы должен быть предъявлен в течение срока после возникновения оснований для иска, за исключением защиты авторских прав на охраняемые в соответствии с законодательством материалы Платформы. При нарушении условий данного пункта любой иск или основания для иска погашаются исковой давностью.
</li>
</ul>
<h2>Заключительные положения 
</h2>
<ul>

<li>Указанное согласие действует бессрочно с момента предоставления данных и может быть отозвано Вами путем подачи заявления администрации Платформы с указанием данных, определенных ст. 14 Закона 152-ФЗ «О персональных данных».
</li>
<li>Администрация Платформы имеет право вносить изменения в настоящее Соглашение. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Соглашения вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Соглашения.
</li>
<li>Отзывы Пользователя, размещенные на Платформе, не являются конфиденциальной информацией и могут быть использованы Администрацией Платформы без ограничений.
</li>
</ul>
      </div>
    </>
  );
};
