import React from "react";
import "./style.scss";
import PlayButton from "../../../Media/Icons/playButton.svg";
import { Logo } from "../../Layout/Common/Logo/Logo";
import { Heading4 } from "../../Headings";

interface VideoProps {
    src?: string;
    autoPlay?: boolean;
    controls?: boolean;
    muted?: boolean;
    loop?: boolean;
    type?: string;
    poster?: string;
}

interface VideoState {
    started: boolean;
    playing: boolean;
}

export class Video extends React.Component<VideoProps, VideoState> {
    videoRef: any = null;
    constructor(props: VideoProps) {
        super(props);

        this.state = {
            started: props.autoPlay || false,
            playing: props.autoPlay || false,
        };

        this.videoRef = React.createRef();
    }

    componentDidMount(): void {
        if (this.props.autoPlay) {
            // setTimeout(() => this.start(), 1000);
        }
    }

    start() {
        this.play(true);
        this.setState({
            started: true,
        });
    }

    play(play: boolean = true) {
        if (play) {
            this.videoRef.current.play();
        } else {
            this.videoRef.current.pause();
        }
    }

    render() {
        let controls = this.props.controls || false;

        return (
            <div className="video">
                <video
                    autoPlay={this.props.autoPlay || false}
                    muted={this.props.muted || false}
                    width="100%"
                    ref={this.videoRef}
                    loop={this.props.loop || false}
                    controls={controls && this.state.started}
                    poster={this.props.poster}
                >
                    <source src={this.props.src} type="video/mp4" />
                </video>
                {
                    controls && !this.state.started && (
                        <div
                            className="video-controls"
                            onClick={() => { this.start() }}
                        >
                            <div className="video-controls-panel">
                                <div
                                    className="video-play-button"
                                >
                                    <img src={PlayButton} alt="Play" />
                                    <div className="video-title">
                                        Смотреть презентацию
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}
