export const content = [
    {
      id: 0,
      title: "Поиск работы",
      description:
        "Карьерный консультант по поиску работы имеет глубокую экспертизу в ситуации на рынке труда, составлении резюме, написании мотивационного письма, проведении собеседований и подготовке к ним.",
      url: "/",
    },
    {
      id: 1,
      title: "Relocate me",
      description:
        "Консультации по релокации и работе за границей для тех, кто решил профессионально укрепиться в другой стране.",
      url: "/",
    },
    {
      id: 2,
      title: "Профориентация",
      description:
        "Наши консультанты помогут специалистам выбрать подходящее направление в соответствии с их ценностями, интересами, особенностями и перспективами, внезависимости от возраста и образования.",
      url: "/",
    },
    {
      id: 3,
      title: "Управление карьерой",
      description:
        "Хотите профессионально расти и найти ресурсы для реализации? Сертифицированные специалисты помогут разработать план действий (или road map) и пройдут  этот путь с вами.",
      url: "/",
    },
    {
      id: 4,
      title: "Эмоциональное здоровье",
      description:
        "Специалисты понимающие механику глубинных психических процессов, помогут вам преодолеть профессиональное выгорание, «стеклянный потолок», выход из декрета, трудности взаимоотношений на работе и другое.",
      url: "/",
    },
    {
      id: 5,
      title: "Корпоративный запрос",
      description:
        "Карьерные консультанты помогут указать на области для развития и подберут нужные инструменты. Актуально как для компаний, так и для учебных учреждений.",
      url: "/",
    },
  ];