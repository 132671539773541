import React from "react";
import { Offer } from "../../Localization/ru_RU/Offer";
import { MainView } from "../../Components/Layout/Common/MainView";


export class OfferView extends React.Component {
    render() {
        return <MainView>
            <Offer/>
        </MainView>
    }
} 