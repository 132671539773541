import React from "react";
import "./style.scss";
import { Heading4 } from "../../Headings";
import Plus from "../../../Media/Icons/plus.svg";
import Minus from "../../../Media/Icons/minus.svg";
import { Tag } from "../../Extra/Tag";
import { Radio } from "../../Extra/Radio";
import { Collapse } from "antd";
import { translations } from "../../../i18n";
import { t } from "i18next";


const Chevron: React.FC<{ up: boolean }> = ({ up }) => {
    return (
        <div>
            {up ? (
                <svg
                    width="18"
                    height="11"
                    viewBox="0 0 18 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1 10L9 2L17 10"
                        stroke="#23232B"
                        strokeWidth="2"
                    />
                </svg>
            ) : (
                <svg
                    width="18"
                    height="11"
                    viewBox="0 0 18 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M17 1L9 9L1 1" stroke="#23232B" strokeWidth="2" />
                </svg>
            )}
        </div>
    );
};

interface FilterProps {
    filterParams: FilterParam[];
    onChange?: Function;
}

interface FilterState {
    filterParams: FilterParam[];
    chosenValues: any;
    filterOpened: boolean;
    clear: boolean;
}

//#region Helpers

export interface FilterParamValue {
    id: number;
    name: string;
}

export interface FilterParam {
    key: string;
    name: string;
    values: any[];
    type?: string;
}

export interface FilterParamProps extends FilterParam {
    onChange: Function;
    clearEnd: Function;
    clear: boolean;
}

interface FilterParamState {
    opened: boolean;
}


interface WrapperProps {
    type?: string;
    values: any;
    name: string;
    onChange: Function;
    clear: boolean;
    clearEnd: Function;
}

interface WrapperState {
    chosenValues: any[];
    opened: boolean;
    tags: any;
}

class RegularWrapper extends React.Component<WrapperProps, WrapperState> {
    constructor(props: WrapperProps) {
        super(props);

        const chosenValues = props.values[0] && props.type !== "tags" ? [props.values[0]] : [];
        const tags: any = {};

        for (const v of props.values) {
            tags[v.id] = {
                opened: false
            }
        }
        this.state = {
            opened: false,
            chosenValues: chosenValues,
            tags: tags,
        };
    }


    openClose(tagId: any) {
        const tags = Object.assign({}, this.state.tags);
        tags[tagId].opened = !tags[tagId].opened;

        this.setState({
            tags: tags,
        });
    }

    componentDidUpdate(prevProps: Readonly<WrapperProps>, prevState: Readonly<WrapperState>) {
        if (this.props.onChange) {

            const prev = JSON.stringify(prevState.chosenValues.sort());
            const curr = JSON.stringify(this.state.chosenValues.sort());

            if (curr !== prev) {
                this.props.onChange(this.state.chosenValues);
            }
        }

        if (this.props.clear) {
            this.clear();
            this.props.clearEnd();
        }

    }

    selectValue(value: any[]) {
        const chosenValues = [...value];

        this.setState({
            opened: false,
            chosenValues: chosenValues,
        });
    }

    selectTag(id: any) {
        const chosenValues = [...this.state.chosenValues];

        if (!chosenValues.includes(id)) {
            chosenValues.push(id);
        } else {
            const index = chosenValues.indexOf(id);
            if (index > -1) {
                chosenValues.splice(index, 1);
            }
        }

        this.setState({
            chosenValues: chosenValues,
        });
    }

    clear() {
        this.setState({
            chosenValues: [],
        });
    }

    isSelected(value: any) {
        if (this.state.chosenValues.includes(value)) {
            return true;
        }
        return false;
    }

    formatTags() {
        const values: any[] = [];

        for (const mainTag of this.props.values) {
            if (mainTag.dependsOn === null) {
                mainTag.subtags = this.props.values.filter(
                    (e: any) => e.dependsOn === mainTag.id
                );
                values.push(mainTag);
            }
        }

        return values;
    }

    render() {
        const type = this.props.type || "regular";

        const values =
            type === "regular" ? this.props.values : this.formatTags();

        return (
            <div className="catalog-filter-element-content">
                <div className="catalog-filter-element-content-wrapper">
                    {type === "tags"
                        ? values.map((v: any) => {
                            return (
                                <div
                                    className="catalog-filter-tags-header"
                                    key={v.id}
                                >
                                    <div
                                        className="tag-controls"
                                        onClick={() => this.openClose(v.id)}
                                    >
                                        <div className="catalog-filter-tags-title">
                                            {v.name}
                                        </div>
                                        <div className="catalog-filter-tags-button">
                                            {this.state.tags[v.id].opened ? (
                                                <img
                                                    alt="-"
                                                    src={Minus}
                                                ></img>
                                            ) : (
                                                <img alt="+" src={Plus}></img>
                                            )}
                                        </div>
                                    </div>
                                    {this.state.tags[v.id].opened && (
                                        <div className="catalog-filter-tags-content">
                                            {v.subtags.map((e: any) => {
                                                return (
                                                    <div
                                                        key={e.id}
                                                        onClick={() =>
                                                            this.selectTag(
                                                                e.id
                                                            )
                                                        }
                                                    >
                                                        <Tag
                                                            checked={this.isSelected(
                                                                e.id
                                                            )}
                                                        >
                                                            {e.name}
                                                        </Tag>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            );
                        })
                        : values.map((v: any) => {
                            return (
                                <div
                                    className="catalog-filter-element-content-container"
                                    key={v.id}
                                >
                                    <Radio
                                        checked={this.isSelected(v.id)}
                                        onChange={() =>
                                            this.selectValue([v.id])
                                        }
                                        id={String(v.id)}
                                        name={this.props.name}
                                    >
                                        {v.name}
                                    </Radio>
                                </div>
                            );
                        })}
                </div>
                <div className="catalog-filter-element-content-control">
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => this.clear()}
                    >
                        <Heading4>Сбросить фильтр</Heading4>
                    </span>
                </div>
            </div>
        );
    }
}

class FilterWrapper extends React.Component<FilterParamProps, FilterParamState> {
    constructor(props: FilterParamProps) {
        super(props);
        this.state = {
            opened: false,
        };
    }

    openClose() {
        const opened = this.state.opened;

        this.setState({
            opened: !opened,
        });
    }

    render() {
        const values = this.props.values || [];
        const onChangeHandler = this.props.onChange || (() => { });
        const onClearHandler = this.props.clearEnd || (() => { });

        return (
            <div className="catalog-filter-element">
                <div
                    className="catalog-filter-element-header"
                    onClick={() => this.openClose()}
                >
                    {this.props.name}
                    <div className="catalog-filter-button">
                        <Chevron up={this.state.opened} />
                    </div>
                </div>
                {this.state.opened && (
                    <RegularWrapper
                        type={this.props.type}
                        onChange={(values: any) => onChangeHandler(values)}
                        name={this.props.name}
                        values={values}
                        clear={this.props.clear}
                        clearEnd={() => onClearHandler()}
                    />
                )}
            </div>
        );
    }
}
//#endregion

export class CatalogFilter extends React.Component<FilterProps, FilterState> {
    constructor(props: FilterProps) {
        super(props);
        this.state = {
            filterParams: props.filterParams || [],
            chosenValues: {},
            filterOpened: false,
            clear: false
        };
    }

    componentDidUpdate(prevProps: Readonly<FilterProps>, prevState: Readonly<FilterState>) {

        const prev = JSON.stringify(prevState.chosenValues);
        const curr = JSON.stringify(this.state.chosenValues);

        if (curr !== prev) {
            if (this.props.onChange) {
                this.props.onChange(this.state.chosenValues);
            }
        }

    }

    updateFilter(key: string, values: any) {
        const chosenValues = Object.assign({}, this.state.chosenValues);

        chosenValues[key] = values;

        this.setState({
            chosenValues: chosenValues,
        });
    }

    clear() {
        this.setState({
            chosenValues: {},
            clear: true
        });
    }

    callback = () => {
        this.setState({
            filterOpened: !this.state.filterOpened,
        });
    }

    clearEnd = () => {
        this.setState({ clear: false });
    }

    render() {
        const params = this.state.filterParams;
        const { Panel } = Collapse;

        return (
            <>
                <Collapse expandIcon={() => <Chevron up={this.state.filterOpened} />} bordered={false} defaultActiveKey={["2"]} className="mainFilter" onChange={this.callback}>
                    <Panel
                        className="filterHeader"
                        header={t(translations.catalog.filters.title)}
                        key="1"
                    >
                        <div className="catalog-filter-body">
                            {params.map((param) => {
                                return (
                                    <FilterWrapper
                                        type={param.type}
                                        key={param.key}
                                        name={param.name}
                                        values={param.values}
                                        onChange={(values: any) => this.updateFilter(param.key, values)}
                                        clear={this.state.clear}
                                        clearEnd={() => this.clearEnd()}
                                    ></FilterWrapper>
                                );
                            })}
                            <div className="catalog-filter-element-content-control">
                                <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.clear()}
                                >
                                    <Heading4>Сбросить все фильтры</Heading4>
                                </span>
                            </div>
                        </div>
                    </Panel>
                </Collapse>
            </>
        );
    }
}
