import React, { Component } from "react";
import classes from "./Catalog.module.scss";
import { StarIcon } from "./StarIcon";

import api from "../../../Utils/publicApi";

interface Props {
    username: string;
    image: any;
    name: string;
    price: string;
    rating: number;
    position: string;
    tags: string[];
}

const Rating: React.FC<{ count: number }> = ({ count }) => {
    return (
        <div className={classes.rating}>
            {new Array(count).fill(null).map((item, idx) => {
                return <StarIcon key={`starIcon${idx}`} />;
            })}
        </div>
    );
};

const Tags: React.FC<{ tags: string[] }> = ({ tags }) => {
    return (
        <div className={classes.tags}>
            {tags.map((item, idx) => {
                return (
                    <span className={classes.tag} key={`tags${idx}`}>
                        {item}
                    </span>
                );
            })}
        </div>
    );
};

const Card: React.FC<Props> = ({
    username,
    image,
    name,
    price,
    rating,
    position,
    tags,
}) => { 
    const blankAvatar = "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y&s=300";

    image = image === null ? {} : image;
    let large = image.large || blankAvatar;
    let medium = image.medium || blankAvatar;

    return (
        <div className={classes.card}>
            <div className={classes.photo}>
                <a href={`/specialists/${username}`}>
                    <img style={{objectFit: "fill", filter: "blur(5px)", transform: "scale(1.1)"}} src={medium} alt="" />
                    <img src={large} alt="" />
                </a>
            </div>
            <div className={classes.cardContent}>
                <div>
                    <span className={classes.name}>{name}</span>
                    {/* <span className={classes.price}>{price}/30 мин</span> */}
                </div>
                <div>
                    <span className={classes.position}>{position}</span>
                    {/* <Rating count={rating} /> */}
                </div>

                <div className="tags">
                    <Tags tags={tags} />
                </div>
            </div>
        </div>
    );
};

interface ConsultantsProps {
    tags?: any[];
    filter?: any;
    consultants: any[]
}

interface ConsultantsState {
}

class Consultants extends Component<ConsultantsProps, ConsultantsState> {
    constructor(props: ConsultantsProps) {
        super(props);
        this.state = {
            consultants: [],
        };
    }

    formatTags(tags: any[])
    {
        const formattedTags: string[] = this.props.tags?.filter(t => tags.includes(t.id)).map(t => String(t.name)) || [];

        return formattedTags;
    }

    formatName(consultant: any)
    {
        const names = [
            consultant.lastName,
            consultant.firstName,
            consultant.middleName,
        ];

        return names.filter(n => n !== null).join(" ");
    }

    render() {
        return (
            <div className={classes.consultant}>
                {this.props.consultants.map((item) => {
                    return (
                        <Card
                            key={`consultants${item.id}`}
                            username={item.username}
                            image={item.avatar}
                            name={this.formatName(item)}
                            price={item.price}
                            rating={item.rating}
                            position={item.position}
                            tags={this.formatTags(item.tags)}
                        />
                    );
                })}
            </div>
        );
    }
}

export { Consultants };
