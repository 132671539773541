import React from "react";
import './loader.scss';
import { Logo } from "../../../../common/Logo/Logo";


export default class Content extends React.Component {
    render() {
        return (
            <>
                <div
                    id="page_loader"
                    className="center">
                </div>
                <div
                    className="loader_logo">
                    <Logo></Logo>
                </div>
                <div
                    className="background">
                </div>
            </>
        )
    }
}