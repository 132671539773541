import React from "react";
import { translations } from "../../../i18n";
import { useTranslation } from "react-i18next";

const Title = () => {
  const { t } = useTranslation();

  return (
    <h1
      dangerouslySetInnerHTML={{
        __html: t(translations.catalog.title),
      }}
    />
  );
};

export { Title };
