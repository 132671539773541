import React from "react";
import { TopMenu } from "../Components/Layout/Common/TopMenu";
import { Footer } from "../Components/Layout/Common/Footer";
import { MenuType } from "../Components/Layout/Common/TopMenu/types";
import { Content } from "../Components/Layout/Catalog/Content";
import { MainView } from "../Components/Layout/Common/MainView";

export class Catalog extends React.Component {

    render() {
        return <MainView>
            <Content />
        </MainView>   
    }
};
