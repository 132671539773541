import React from "react";
import { MainView } from "../Layout/Common/MainView";
import { useParams } from 'react-router-dom';
import { Paths } from "../../Utils";
import Loader from '../Layout/Common/Loader/Loader';
import api from "../../Utils/publicApi";
import { PrimaryButton, Secondary1Button, Secondary2Button } from "../Buttons";
import "./style.scss";


function OrderDetails(props) {
    const params = useParams();

    return (
        <OrderDetailsInner
            {...props}
            params={params}
        />
    );
};


function ButtonsBlock() {
    return (
        <>
            <Secondary1Button onClick={() => { window.open(Paths.Root, "_self") }}>
                Перейти на главную
            </Secondary1Button>

            <Secondary2Button onClick={() => { window.open(process.env.REACT_APP_PA_HOST, "_self") }}>
                Личный кабинет
            </Secondary2Button>

            <PrimaryButton onClick={() => { window.open(Paths.Catalog, "_self") }}>
                Каталог специалистов
            </PrimaryButton>
        </>
    )
}


function ErrorScreen() {
    return (
        <div className="order-status-error">
            <div className="order-status-error-backdrop" />
            <div className="order-status-error-body">
                <div className="error-body-data">
                    <div className="error-body-title">
                        {
                            `Произошла ошибка :(`
                        }
                    </div>

                    <div className="error-body-text">
                        {
                            `Обновите страницу или попробуйте позднее`
                        }
                    </div>
                </div>

                <div className="error-body-buttons">
                    <ButtonsBlock />
                </div>
            </div>
        </div>
    )
}


class OrderDetailsInner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isError: false,
            order: null
        }
    }


    async componentDidMount() {
        this.setState({ isLoading: true });
        await this.getOrderData();
        this.setState({ isLoading: false });
    }

    setError = () => {
        document.body.style.overflow = "hidden";
        this.setState({
            isError: true
        })
    }

    getOrderData = async () => {
        try {
            if (!this.props.params || (this.props.params && !this.props.params.orderId)) {
                throw new Error("No operation id specified");
            }

            const result = await api.getOperation(this.props.params.orderId);

            if (!result || (result && !result.id) || (result && result._serviceInfo && result._serviceInfo.statusText !== "OK")) {
                throw new Error("No order data");
            }

            await new Promise(resolve => setTimeout(resolve, 500));

            this.setState({
                order: result,
                isError: false
            })

        } catch (e) {
            console.log(e);
            this.setError();
        }
    }

    render() {
        const {
            isLoading,
            isError,
            order
        } = this.state;

        return (
            <>
                {
                    isError &&
                    <ErrorScreen />
                }

                <MainView>
                    <div className="order-status">
                        <div className="order-status-inner">
                            {
                                isLoading &&
                                <Loader />
                            }

                            {
                                !isLoading && !isError && order &&
                                <div className="order-inner-data">
                                    <div className="order-data">
                                        <svg
                                            className="image"
                                            id="Layer_1"
                                            style={{ enableBackground: 'new 0 0 512 512' }}
                                            version="1.1"
                                            viewBox="0 0 512 512"
                                            xmlSpace="preserve"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                        >
                                            <style type="text/css">
                                                {`.st0 {fill: #41AD49;}`}
                                            </style>
                                            <g>
                                                <polygon
                                                    className="st0"
                                                    points="434.8,49 174.2,309.7 76.8,212.3 0,289.2 174.1,463.3 196.6,440.9 196.6,440.9 511.7,125.8 434.8,49"
                                                />
                                            </g>
                                        </svg>
                                        <div className="order-title">
                                            {
                                                `Заказ №${order.id} оплачен`
                                            }
                                        </div>
                                        <div className="order-text">
                                            Мы уверены, что Ваша консультация пройдет плодотворно и легко!
                                        </div>
                                    </div>

                                    <div className="order-buttons">
                                        <ButtonsBlock />
                                    </div>
                                </div>
                            }

                            {
                                !isError && !isLoading && !order &&
                                <ErrorScreen />
                            }
                        </div>
                    </div>
                </MainView>
            </>
        )
    }
}

export default OrderDetails;