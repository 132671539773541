import React from "react";
// import { Catalog } from "./pages/Catalog";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Path } from "./common/TopMenu/types";
// import { Auth } from "./pages/Auth";
import { Main } from "./Views/Main";
import { Catalog } from "./Views/Catalog";
import Specialist from "./Views/Specialist";
import OrderSuccess from "./Components/BookingSuccess/index";
// import { SuccessRegistration } from "./pages/SuccessRegistration";
// import { ErrorRegistration } from "./pages/ErrorRegistration";
// import { Error } from "./pages/Error";

import { TermsView } from "./Views/Information/Terms";
import { OfferView } from "./Views/Information/Offer";
import { AgreementView } from "./Views/Information/Agreement";

import "./App.css";
import "./fonts/fonts.css";

export default class App extends React.Component {

  componentDidMount() {
    window.history.scrollRestoration = 'manual';
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path={Path.Root} element={<Main />} />
          <Route path={Path.Catalog} element={<Catalog />} />
          <Route path={Path.Specialist} element={<Specialist />} />

          <Route path={"/information/terms"} element={<TermsView />} />
          <Route path={"/information/offer"} element={<OfferView />} />
          <Route path={"/information/agreement"} element={<AgreementView />} />
          <Route path={"/payments/operations/status/*"} element={<OrderSuccess />} />
          {/* <Route path={Path.Consultant} element={<Consultant />} />
        <Route path={Path.Auth} element={<Auth />} />
        <Route path={Path.SuccessRegistration} element={<SuccessRegistration />} />
        <Route path={Path.ErrorRegistration} element={<ErrorRegistration />} />
        <Route path={Path.Error} element={<Error />} /> */}
        </Routes>
      </BrowserRouter>
    );
  }
};
