import React from "react";
import { Pagination } from "./Pagination";
import { Search } from "./Search";
import { Title } from "./Title";
import { Consultants } from "./Consultants";
// import { Filters } from "./Filter/Filters";
import { CatalogFilter, FilterParam } from "../../Layout/Filter";
import { useWindowSize } from "../../../Utils/dimension.hook";
import Loader from '../Common/Loader/Loader';

import classes from "./Catalog.module.scss";
import api from "../../../Utils/publicApi";

import { Heading3 } from "../../Headings";

import { Pagination as AntPagination } from "antd";

const params: FilterParam[] = [
    {
        key: "key1",
        name: "Some regular filter 1",
        type: "regular",
        values: [
            {
                id: 1,
                name: "Some text 1",
            },
            {
                id: 2,
                name: "Some text 2",
            },
            {
                id: 3,
                name: "Some text 3",
            },
        ],
    },
    {
        key: "key2",
        name: "Some regular filter 2",
        type: "regular",
        values: [
            {
                id: 4,
                name: "Some text 4",
            },
            {
                id: 5,
                name: "Some text 5",
            },
            {
                id: 6,
                name: "Some text 6",
            },
        ],
    },
    {
        key: "key3",
        name: "Some regular filter 3",
        type: "regular",
        values: [
            {
                id: 7,
                name: "Some text 7",
            },
            {
                id: 8,
                name: "Some text 8",
            },
            {
                id: 9,
                name: "Some text 9",
            },
        ],
    },
    {
        key: "tagsKey1",
        name: "Some tag filter",
        type: "tags",
        values: [
            {
                id: 1,
                name: "Main Tag 1",
                description: null,
                dependsOn: null,
                typeId: 1,
                details: { color: "#C8D9E7" },
            },
            {
                id: 2,
                name: "Main Tag 2",
                description: null,
                dependsOn: null,
                typeId: 1,
                details: { color: "#C8D9E7" },
            },
            {
                id: 3,
                name: "Sub Tag 1",
                description: null,
                dependsOn: 1,
                typeId: 1,
                details: { color: "#C8D9E7" },
            },
            {
                id: 4,
                name: "Sub Tag 2",
                description: null,
                dependsOn: 1,
                typeId: 1,
                details: { color: "#C8D9E7" },
            },
            {
                id: 5,
                name: "Sub Tag 3",
                description: null,
                dependsOn: 2,
                typeId: 1,
                details: { color: "#C8D9E7" },
            },
            {
                id: 6,
                name: "Sub Tag 4",
                description: null,
                dependsOn: 2,
                typeId: 1,
                details: { color: "#C8D9E7" },
            },
            {
                id: 7,
                name: "Sub Tag 5 (long text)",
                description: null,
                dependsOn: 2,
                typeId: 1,
                details: { color: "#C8D9E7" },
            },
            {
                id: 8,
                name: "Sub Tag 6 (text longeeeeeer)",
                description: null,
                dependsOn: 2,
                typeId: 1,
                details: { color: "#C8D9E7" },
            },
        ],
    },
];

// const DesktopLayout = () => {
//     return (
//         <>
//             <div className={classes.content}>
//                 <div className={classes.sidebar}>
//                     <CatalogFilter filterParams={params} />
//                 </div>
//                 <div className={classes.layout}>
//                     <Title />
//                     {/* <Search /> */}
//                     <Consultants />
//                 </div>
//             </div>
//             <div className={classes.bottomBlock}>
//                 <div className={classes.space}></div>
//                 <div className={classes.paginationBlock}>
//                     {/* <Pagination /> */}
//                 </div>
//             </div>
//         </>
//     );
// };

// const MobileLayout = () => {
//     return (
//         <div className={classes.layout}>
//             <Title />
//             {/* <Search /> */}
//             <CatalogFilter filterParams={params} />
//             <Consultants />
//             <Pagination />
//         </div>
//     );
// };

interface CatalogContentState {
    filterParams: any[];
    consultants: any[];
    tags: any[];
    loading: boolean;
    targetFilter: any;
    offset: number;
    limit: number;
    total: number;
    current: number;
}

class Content extends React.Component<{}, CatalogContentState> {

    constructor(props: any) {
        super(props);

        this.state = {
            filterParams: [],
            tags: [],
            loading: true,
            targetFilter: {},
            consultants: [],
            limit: 8,
            offset: 0,
            total: 0,
            current: 1
        }
    }

    async componentDidMount() {
        this.setState({
            loading: true,
        })
        const extra = await api.getPlatformExtra();

        const extraWL: any = {
            "activitySpheres": {
                name: "Направление",
                key: "activitySpheres"
            },
            "communications": {
                name: "Способ связи",
                key: "communications"
            },
            "professions": {
                name: "Типы специалистов",
                key: "professions"
            },
            "usersTypes": {
                name: "Моя категория",
                key: "usersTypes"
            },
            // "workExperiences": {
            //     name: "Опыт работы",
            //     key: "workExperienceId"
            // },
        }

        const filterParams: FilterParam[] = [];

        for (const k in extra) {
            if (Object.keys(extraWL).includes(k)) {
                const filterParam: FilterParam = {
                    key: extraWL[k].key,
                    name: extraWL[k].name,
                    values: extra[k],
                }
                filterParams.push(filterParam);
            }
        }

        if (Object.keys(extra).includes("tags")) {
            const filterParam: FilterParam = {
                key: "tags",
                name: 'Запрос (тэги)',
                values: extra["tags"],
                type: "tags",
            }
            filterParams.push(filterParam);
        }

        const { limit, offset } = this.state;
        const getConsultants = await api.getCatalog({}, { limit, offset });

        this.setState({
            filterParams: filterParams,
            consultants: getConsultants.profiles,
            tags: extra["tags"] || [],
            loading: false,
            total: getConsultants.total,
        })
    }

    async updateFilter(filter: any) {
        const { limit } = this.state;
        const getConsultants = await api.getCatalog(filter || {}, { limit, offset: 0 });
        this.setState({
            consultants: getConsultants.profiles,
            offset: 0,
            targetFilter: filter,
            total: getConsultants.total,
            current: 1
        })
    }

    changePage = async (page: number) => {
        this.setState({ loading: true });
        const { limit, targetFilter } = this.state;
        const offset = (limit * page) - limit;
        const getConsultants = await api.getCatalog(targetFilter || {}, { limit, offset });
        window.scrollTo(0, 0);
        this.setState({
            consultants: getConsultants.profiles,
            offset: offset,
            loading: false,
            current: page
        })
    }

    itemRender = (current: any, type: string, originalElement: any) => {
        if (type === "prev") {
            return <a href='#'>Назад</a>;
        }
        if (type === "next") {
            return <a href='#'>Вперед</a>;
        }
        return originalElement;
    }

    render() {
        return (
            <>
                {
                    this.state.loading ?
                        <Loader />
                        :
                        <>
                            <div className={classes.title}>
                                <Title />
                            </div><div className={classes.content}>
                                <div className={classes.sidebar}>
                                    {this.state.filterParams.length > 0 &&
                                        <CatalogFilter onChange={(filter: any) => this.updateFilter(filter)} filterParams={this.state.filterParams} />}
                                </div>
                                <div className={classes.layout}>
                                    {/* <Search /> */}
                                    {this.state.tags && this.state.tags.length > 0 && <>
                                        {this.state.consultants && this.state.consultants.length > 0 ?
                                            <Consultants consultants={this.state.consultants} filter={this.state.targetFilter} tags={this.state.tags} />
                                            :
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}><Heading3>С выбранными фильтрами ничего не найдено :(</Heading3></div>}
                                    </>}
                                </div>
                            </div>
                            <div className={classes.bottomBlock}>
                                <div className={classes.space}></div>
                                <div className={classes.paginationBlock}>
                                    {
                                        this.state.total > 0 &&
                                        <AntPagination
                                            itemRender={this.itemRender}
                                            className={classes.pagination}
                                            defaultPageSize={this.state.limit}
                                            defaultCurrent={1}
                                            total={this.state.total}
                                            onChange={this.changePage}
                                            showSizeChanger={false}
                                            current={this.state.current}
                                        />
                                    }
                                </div>
                            </div>
                        </>
                }
            </>
        );
    }
}

export { Content };
