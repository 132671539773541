import React from "react";
import "./style.scss";

interface RadioProps {
    id: string;
    name: string;
    value?: string;
    checked?: boolean;
    auto?: boolean;
    onChange?: (id: string, checked: boolean) => void;
}

export class Radio extends React.Component<RadioProps> {
    render() {
        const checked =
            this.props.checked === undefined ? false : this.props.checked;
        const onChangeHandler =
            this.props.onChange || ((id: string, checked: boolean) => { });
        const auto = this.props.auto || false;
        return (
            <div className="radio"
                onClick={(e: any) => {
                    onChangeHandler(this.props.id, e.target.checked)
                }}>
                <div className="radio-wrap"
                >
                    {
                        auto ? (
                            <input
                                type="radio"
                                defaultChecked={checked}
                                name={this.props.name}
                                id={this.props.id}
                                ref={(el) => "ref_" + el}
                                onChange={() => { }}
                            />
                        ) : (
                            <input
                                type="radio"
                                checked={checked}
                                name={this.props.name}
                                id={this.props.id}
                                ref={(el) => "ref_" + el}
                                onChange={() => { }}
                            />
                        )}
                </div>
                {
                    this.props.children && <span className="radio-label">{this.props.children || ""}</span>
                }
            </div>
        );
    }
}
