import React from "react";
import { Header } from "../Header";
import { Footer } from "../Footer";

interface MainViewProps {

}

export class MainView extends React.Component<MainViewProps, {}> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return(
            <div>
                <Header></Header>
                {this.props.children}
                <Footer></Footer>
            </div>
        )
    }
};
