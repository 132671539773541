import React from "react";
import { TopMenu } from "../../Components/Layout/Common/TopMenu";
import {MenuType} from "../../Components/Layout/Common/TopMenu/types";
import { Footer } from "../../Components/Layout/Common/Footer";

export const Offer = () => {
  return (
    <>
      <div className="wrapper" style={{marginBottom: "50px"}}>
      <h1>ПУБЛИЧНАЯ ОФЕРТА</h1>

<p>Договор заключается на условиях настоящего документа, представляющего собой публичную оферту — предложение, выражающее намерение «ИП Кузнецова Александра Дмитриевна» (далее – ("компания Adabstar","платформа Adabstar") считать себя заключившей агентский договор (далее — «Договор», «Партнерский договор») с любым физическим или юридическим лицом (Партнером), которым будет принято предложение путём присоединения к настоящему документу в целом.
</p>
<p>Настоящая Публичная оферта ("Политика платежей") регулирует Платежные услуги, предоставляемые вам компанией Adabstar. Термины с заглавной буквы (включая термин "Adabstar"), используемые, но не определенные в настоящей Политике платежей, имеют значения, приписанные таким терминам в наших Условиях предоставления услуг, которые доступны по адресу www.adabstar.ru.
</p>
<h2>1. ОСНОВНЫЕ ТЕРМИНЫ ДЛЯ ПЛАТЕЖНЫХ УСЛУГ
</h2>

<p>"Кредиты Adabstar" означают виртуальную валюту, приобретенную у Adabstar, которая может быть использована исключительно на Платформе Adabstar для приобретения Услуг Партнера и не имеет никакой ценности за пределами Платформы Adabstar. Все Услуги Партнера на Платформе Adabstar оплачиваются Кредитами Adabstar.
</p>
<p>"Плата за услугу" или "Плата за пакет услуг" означает суммы, подлежащие уплате Пользователем за подтвержденные услуги, которые были даны Партнером. Только Партнер, а не Adabstar, несет ответственность за установление суммы Платы за услугу или Платы за пакет услуг.
</p>
<p>"Комиссионное вознаграждение платформы" означает плату, которую Adabstar взимает с Партнера при использовании Услуг платформы Adabstar.
</p>
<p>"Кошелек Пользователя" означает учетную запись, созданную Пользователем для покупки Кредитов Adabstar, которые будут расходоваться на Платформе Adabstar. Кредиты Adabstar в Кошельке Пользователя могут быть использованы для оплаты Услуг Партнеров, предоставляемых Партнерами, и других услуг, предлагаемых на Платформе Adabstar или через нее.
</p>
<p>"Способ оплаты" означает способ или услугу, доступную на Платформе Adabstar для приобретения Пользователями Кредитов Adabstar, например, QR-коды СБП и банковская карта: пока только карты, выпущенные в России Visa, MasterCard, «Мир».
</p>
<p>"Кошелек Партнера" означает учетную запись, созданную Партнером для получения оплаты за услуги Партнера.
</p>
<p>"Способ вывода средств" означает способ или услугу, доступную на Платформе Adabstar для Партнеров для получения наличных в обмен на Кредиты Adabstar, например, по реквизитам банковской карты: р/с, БИК, Наименование банка и остальные данные.
</p>

<h2>2. ДОГОВОР НА ОКАЗАНИЕ УСЛУГИ 
</h2>

После подтверждения Пользователем и Партнером Услуг Партнера, времени и цены, считается, что Пользователь и Партнер заключают договор, в соответствии с которым Пользователь соглашается приобрести, а Партнер соглашается предоставить Услуги Партнера ("Договор на Услугу(и)"). Вы соглашаетесь не заключать никаких договоров, которые противоречат Условиям Adabstar или другим Политикам Adabstar. Пользователь и Партнер обязуются и соглашаются действовать добросовестно и честно при выполнении Контракта на Услугу(и). Кроме того, Пользователь и Партнер признают и соглашаются, что ценность, репутация и деловая репутация Платформы Adabstar зависит от выполнения ими вышеуказанных обязательств и соглашений. Поэтому Пользователь и Партнер соглашаются с тем, что Adabstar имеет право предпринять такие действия в отношении Контракта на Услугу(и), включая, но не ограничиваясь, приостановление, прекращение или судебные иски, которые Adabstar по своему усмотрению сочтет необходимыми для защиты ценности, репутации и деловой репутации Платформы Adabstar.

<h2>3. ФИНАНСОВЫЕ УСЛОВИЯ ДЛЯ ПОЛЬЗОВАТЕЛЕЙ
</h2>
Пользователи должны использовать Кредиты Adabstar для приобретения Услуг Партнера у Партнера. Adabstar предоставляет каждому Пользователю Кошелек Пользователя для хранения Кредитов Adabstar.

<h3>3.1 Приобретение Кредитов Adabstar
</h3>
Чтобы приобрести Кредиты Adabstar, вы можете использовать один из существующих Способов оплаты, на момент совершения акта оплаты, для оплаты желаемого количества Кредитов Adabstar через ваш Аккаунт Adabstar. Предоставляя Adabstar необходимую информацию для соответствующего Способа оплаты, вы разрешаете нам взимать плату за выбранные вами суммы на странице оплаты вашего Счета Adabstar. После успешной транзакции на вашем Пользовательском кошельке будет соответствующая сумма Кредитов Adabstar. Вы признаете и соглашаетесь, что приобретенные вами Кредиты Adabstar будут использоваться для оплаты услуг Adabstar или Партнера на Платформе Adabstar. Если вы являетесь компанией, приобретенные вами Кредиты Adabstar могут быть распределены между Счетом Adabstar сотрудников компании.

<h3>3.2 Возврат средств при авторизованных платежах запрещен
</h3>

После успешной транзакции на вашем Пользовательском кошельке будет соответствующая сумма Кредитов Adabstar. Вы признаете и соглашаетесь, что приобретенные вами Кредиты Adabstar будут использоваться для оплаты услуг Adabstar или Партнера на Платформе Adabstar. Если вы являетесь компанией, приобретенные вами Кредиты Adabstar могут быть распределены между Счетом Adabstar сотрудников компании. Вместе с этим Пользователь соглашается, что обратная транзакция по обмену Кредитов Adabstar обратно на деньги и возврат средств на, например, карту или другой Лицевой счет Пользователя, запрещен настоящей Офертой. Платформа Adabstar может произвести возврат Кредитов Adabstar на личный Кошелек Пользователя для приобретения других Услуг Платформы Adabstar или Услуг Партнеров Adabstar в случае зафиксированных нарушений или обстоятельств, при которых возникли РАЗНОГЛАСИЯ МЕЖДУ ПАРТНЕРОМ И ПОЛЬЗОВАТЕЛЕМ П.10 настоящей Оферты.

<h3>3.3 Плата за использование сторонних методов оплаты
</h3>
При покупке Кредитов Adabstar с вас могут взиматься дополнительные сборы третьей стороной, которая предоставляет соответствующий Способ оплаты. Эти сборы не находятся под контролем Adabstar, и Adabstar снимает с себя всю ответственность в этом отношении. На любой используемый вами Способ оплаты могут распространяться дополнительные положения и условия, с которыми мы рекомендуем вам ознакомиться перед использованием такого Способа оплаты.

<h3>3.4 Отмененные запросы на Услуги
</h3>
Если запрос на услугу отменен Пользователем, отклонен Партнером или истек (не подтвержден Партнером в течение периода запроса), вы получите 100% возмещение в виде кредитов Adabstar на кошелек Пользователя.

<h3>3.5 Подтверждение Услуг
</h3>
Механизм подтверждения Услуг: Пользователь, выбирает себе подходящего Партнера, далее в предложенном календаре Партнера, выбирает себе удобное подходящее время и день оказания Услуги. Партнер, видит новый запрос на Услугу из ранее указанного им перечня услуг и самостоятельно определяет подтверждать ему Услугу с Пользователем или нет, ввиду разных причин, которые затрудняют возможность Партнеру оказать услугу Пользователю в выбранный Пользователем период времени. В результате Партнер может подтвердить время и день оказания Услуги Пользователю, который тот выбрал или предложить другой день и/или время, путем автоматизированного указания другого времени внутри ЛК Партнера или путем согласования нового времени через внутренний чат платформы Adabstar. Услуга считается подтвержденной, когда Пользователь и Партнер подтвердили одинаковое время в своих личных кабинетах.

<h3>3.6 Отзывы и оценки Услуг
</h3>
В качестве Пользователя, когда вы подтверждаете, что Услуга прошла успешно, вас могут попросить оставить отзыв и поставить оценку Партнеру. Оставлять отзыв или оценку необязательно. Однако, если вы это сделаете, вы соглашаетесь, что ваш отзыв должен быть подлинным и честным, и вы не будете являться мошенничеством, спамом, оскорблять, преследовать, угрожать или иным образом злоупотреблять системой отзывов.

Вы соглашаетесь сообщать о нарушениях или злоупотреблениях в нашей системе рейтингов и отзывов, обратившись в Службу поддержки Клиентов, и соглашаетесь не предпринимать никаких действий, подрывающих целостность системы отзывов.

<h2>4. ФИНАНСОВЫЕ УСЛОВИЯ ДЛЯ ПАРТНЕРОВ
</h2>

Партнерам необходимо использовать Кредиты Adabstar для получения оплаты за Услуги Партнера от Пользователей. Adabstar предоставляет каждому Партнеру "Кошелек Партнера" для хранения Кредитов Adabstar и конвертации Кредитов Adabstar в деньги, согласно существующим на платформе способам вывода средств, например, по реквизитам карты самозанятого, если это физическое лицо или на расчетный счет ИП и юридического лица, с обменом соответствующими документами по документообороту, принятому в Российской Федерации.

<h3>4.1 Принятие запросов на Услуги
</h3>

Партнеры будут получать запросы на оказание Услуг от Пользователей ("Запрос на Услугу"). Принимая запрос на Услугу, вы соглашаетесь оказать Услугу для Пользователя в день и время, указанные в соответствующем запросе на Услугу. Вы можете отклонить любой Запрос на Услугу. Если вы не ответите, срок действия Запроса на Услугу автоматически истечет.

<h3>4.2 Отмена запросов на Услуги
</h3>
У Партнера или Пользователя, в результате взаимодействия на платформе могут возникать ситуации, при которых будет требоваться отмена запросов на Услуги. Платформа Adabstar это понимает и признает право на возникновение таких прецедентов. Мы рассматриваем несколько сюжетов при которых возможны такие прецеденты, например такие, но не ограничиваясь ими: 
<p>А. Пользователь отменил запрос на Услугу Партнера
</p>
<p>Б. Партнер отменил запрос на предоставление Услуги
</p>
Мы предлагаем универсальное решение, которое стремиться быть универсально честным и беспристрастным по отношению к обеим сторонам: Отмена запросов на Услуги может быть произведена не менее, чем за 24 часа до согласованного начала времени и дня оказания Услуги, зафиксированных в календаре. Однако если Отмена произошла в период менее, чем за 24 часа, то Adabstar рекомендует Партнеру и Пользователю общаться напрямую и попытаться разрешить разногласия самостоятельно. Если Партнер и Пользователь не могут разрешить разногласия и просят Adabstar выступить посредником, то Платформа включается в процесс согласно П.10 настоящей Оферты.

<h3>4.3 Комиссионное сборы платформы Adabstar
</h3>
Adabstar взимает свой комиссионный сбор, когда Пользователь подтверждает успешное оказание Услуги. За каждую успешно оказанную Услугу комиссия составляет от 15% от общей стоимости такой Услуги ("Комиссионные сборы платформы") и регулируется с Партнером индивидуально. Окончательные процентные данные зафиксированы в Партнерском договоре с платформой между Партнером и Платформой Adabstar. Комиссионный сбор вычитается при переводе Кредитов Adabstar в Кошелек Партнера, услуги не облагаются НДС в связи с применением упрощенной системы налогообложения (УСН).

Баланс, указанный в Кошельке Партнера, является полным балансом после вычета всех комиссий. Суммы могут быть округлены в большую или меньшую сторону по копейкам, как описано в разделе "Округление" ниже.

В случае возврата денежных средств Пользователю, с Партнера не взимаются комиссионные сборы в отношении Кредитов Adabstar, которые возвращаются обратно Пользователю.

<h3>4.4 Запрет на обход Комиссионных сборов
</h3>
Партнеры не должны предпринимать никаких действий, направленных на обход Комиссионных сборов, такие действия включают, но не ограничиваются следующим: (а) предложение, вымогательство или прием оплаты вне Платформы Adabstar или другими способами, кроме как через Платежные услуги; или (б) сообщение или перечисление неточной цены за Услугу на Платформе Adabstar, или (в) взимание платы за Услугу, которая отличается от суммы, согласованной между Пользователем и Партнером.

<h3>4.5 Снятие/вывод средств
</h3>
При снятии/выводе Кредитов Adabstar с вас могут взиматься дополнительные сборы третьей стороной, предоставляющей соответствующий способ снятия средств. Эти сборы не находятся под контролем Adabstar, и Adabstar снимает с себя всю ответственность в этом отношении. Независимо от используемого вами способа вывода средств, на него могут распространяться дополнительные положения и условия, с которыми мы рекомендуем вам ознакомиться перед использованием такого способа вывода средств.

<h3>4.6 Отмены или возвраты Услуг
</h3>
Как Партнер, вы признаете и соглашаетесь с тем, что Adabstar может по своему усмотрению отменить подтвержденный Запрос на Услугу в соответствии с Условиями Adabstar и другими Политиками Adabstar. Вы также признаете и соглашаетесь, что Adabstar и соответствующий Пользователь не несут никакой ответственности за такие отмены или возвраты.

<h3>4.7 Округление
</h3>
Adabstar может по своему усмотрению округлять суммы, подлежащие выплате от Партнера или Пользователя, до ближайшей целой функциональной базовой единицы, в которой деноминирована валюта (например, до ближайшего рубля); например, Adabstar может округлить сумму в 5099.50р до 5100.00р, а сумму в 5000.49р до 5000.00р.

<h2>5. Баланс Партнера
</h2>
Баланс, указанный в кошельке Партнера, является полным балансом после вычета всех сборов. Суммы могут быть округлены в большую или меньшую сторону, как описано в разделе "Округление" выше.

<h2>6. НАЛОГИ
</h2>
Партнеры несут полную ответственность за определение своих применимых налоговых обязанностей в связи с доходом, полученным от их Услуг Партнера, а также несут полную ответственность за перечисление соответствующему органу любых применимых налогов, которые должны быть уплачены. Adabstar не может и не предоставляет консультаций по вопросам налогообложения ни одному из Членов.
Платформа Adabstar не является налоговым агентом Партнера по НДФЛ и не должна начислять страховые взносы на доход Партнера. Если в день вывода Кредитов Adabstar Партнер не будет состоять на учете в качестве плательщика НПД (не будет являться самозанятым), то Платформа имеет право не выплачивать Кредиты Adabstar до момента предоставления документов о постановке на учет в качестве плательщика НПД или регистрации в качестве ИП и выдачи соответствующих реквизитов.
Если Платформа Adabstar понесет убытки в результате снятия Партнера с учета в качестве плательщика налога на профессиональный доход (как добровольного, так и принудительного), то Партнер обязан возместить все убытки Платформе, в том числе по начисленным и уплаченным суммам НДФЛ и страховых взносов, начисленных на сумму полученных Партнером денежных средств.

<h2>7. ПОЛИТИКА В ОТНОШЕНИИ КРЕДИТОВ ADABSTAR
</h2>

<h3>7.1 Кредиты Adabstar не являются банковскими депозитами
</h3>
Вы признаете, что: (1) Adabstar не является банком, а кошельки Пользователей и Партнеров - это платежные услуги, а не банковские услуги; и (2) Adabstar не выступает в качестве доверенного лица или фидуциария в отношении ваших средств, а действует только как агент и хранитель. (3) ADABSTAR НЕ ЯВЛЯЕТСЯ БАНКОМ, И СУММЫ, ПЕРЕВЕДЕННЫЕ ЧЕРЕЗ КОШЕЛЬКИ ПОЛЬЗОВАТЕЛЕЙ ИЛИ ПАРТНЕРОВ ИЛИ ХРАНЯЩИЕСЯ В НИХ, НЕ ЯВЛЯЮТСЯ ЗАСТРАХОВАННЫМИ ВКЛАДАМИ.

<h3>7.2 Политика истечения срока действия Кредитов Adabstar
</h3>

Внутренние средства оплаты - «Кредиты Adabstar» не имеют срока действия и сохраняются в обиходе на платформе Adabstar на весь период работы Платформы Adabstar на территории Российской Федерации и/или могут регулироваться дополнительными прецедентами, в результате которых на Платформе Adabstar будет происходить изменения Платежной политики по обстоятельствам в том числе, не зависящим от Платформы Adabstar. Платформа Adabstar будет производить уведомление каждого Пользователя и Партнера при возникновении прецедентов или изменений, влекущих за собой изменение Платежной политики.

<h3>7.3 Валютный риск
</h3>
Кредиты Adabstar привязаны к доллару США. Adabstar не несет ответственности за колебания курсов валют, возникающие при выставлении счетов или зачислении средств на кредитные или дебетовые карты, деноминированные в валюте, отличной от доллара США, к которой привязаны Кредиты Adabstar, а также не несет ответственности за колебания курсов валют, возникающие при получении или отправке платежей через сторонние платежные службы при покупке Кредитов Adabstar или снятии остатка Кредитов Adabstar.
Кредиты Adabstar пересчитываются по индикатору скользящей средней валютный пар доллар США/российский рубль. Этот индикатор был выбран для избежания резких всплесков и большой волатильности. Мы ежедневно снимаем официальные показатели валютных пар доллар США/российский рубль. Пользователь при оплате Кредитов Adabstar видит сумму Кредитов, которую он приобретает и сумму в пересчете на российские рубли, сколько это будет стоить. Партнеры Adabstar выставляют свои услуги в Кредитах Adabstar, однако для удобства внутренний конвертер показывает сумму эквивалент в российских рублях. Для примера, в определенный момент времени по индикатору скользящей средней доллар США/российский рубль, показатель доллара США равен 61,14 российских рублей. Таким образом на Платформе сумма в 5000 российских рублей будет равна 81,77 Кредитов Adabstar. 

<h3>7.4 Ограничения Кредитов Adabstar
</h3>

Кредиты Adabstar действительны только при покупке на Платформе Adabstar или через авторизованных партнеров Adabstar. Кредиты Adabstar в виде подарочных карт или ваучеров Adabstar не могут быть перепроданы или обменены на наличные. Кредиты Adabstar, выкупленные с помощью подарочных карт или ваучеров, приобретенных через неофициальные источники или каналы, подлежат аннулированию без предварительного уведомления.

<h2>8. ПРАВА ADABSTAR В ОТНОШЕНИИ СЧЕТОВ И СРЕДСТВ ADABSTAR
</h2>

<h3>8.1 Безопасность и финансовое мошенничество
</h3>

В качестве меры безопасности Adabstar может установить ограничения на транзакции для некоторых или всех Пользователей и Партнеров, касающиеся стоимости любой транзакции или выплат, совокупной стоимости всех транзакций или выплат за определенный период времени или по количеству транзакций в день или другой период времени. Мы можем установить эти ограничения до полной нормализации работы платформы, если сочтем это необходимым по соображениям безопасности или для предотвращения мошенничества.

Adabstar оставляет за собой право аннулировать покупки на Кредиты Adabstar, если будет установлено, что покупки являются мошенническими. Это касается и Кредитов Adabstar, которые были выкуплены в результате мошеннических покупок подарочных карт Adabstar.

<h3>8.2 Оговорка о правах на ошибочные транзакции
</h3>
Adabstar оставляет за собой право требовать возмещения от любого Партнера или Пользователя, если Adabstar, по своему усмотрению, определит, что произошли ошибочные или дублирующие операции. Adabstar может получить такое возмещение путем вычета из будущих платежей, причитающихся такому Партнеру, путем отмены любых Кредитов Adabstar на Кошелек Партнера или путем получения возмещения от такого Партнера любым другим законным способом.

<h3>8.3 Запрет на дружеское мошенничество
</h3>
"Дружеское мошенничество" — это когда человек инициирует спор о мошенничестве с провайдером платежей, утверждая, что его кредитная карта (или другой способ оплаты) не предназначалась для использования. Поскольку Adabstar не предлагает возврат средств, пользователи, не согласные с политикой, могут пытаться использовать "Дружеское мошенничество", чтобы получить возврат средств.

Такое поведение является незаконным и запрещено всеми платежными провайдерами. Adabstar также строго выступает против «Дружеского мошенничества». В случае обнаружения "Дружеского мошенничества" аккаунт Adabstar и кошелек Пользователя будут деактивированы, приобретенные кредиты конфискованы, а сам Пользователь будет отмечен как лицо, представляющее угрозу финансовой безопасности.

В случае честной ошибки, Adabstar может отменить транзакцию. Мы настоятельно рекомендуем Пользователям обращаться в службу поддержки Adabstar для разрешения любых финансовых споров.

<h2>9. ЗАПРЕТЫ
</h2>
Помимо соблюдения ограничений, касающихся ненадлежащего использования Платформы Adabstar, описанных в Разделе 11 (Ограничения) Условий Adabstar, вы понимаете и соглашаетесь, что несете полную ответственность за соблюдение любых применимых законов, правил, положений и налоговых обязательств, которые могут применяться к вам в связи с использованием Платежных услуг. Кроме того, вы не имеете права и соглашаетесь, что не будете:

<p>А) Нарушать любые местные, государственные, провинциальные, национальные или другие законы или постановления, или любые постановления суда, такие как отмывание денег и интернет-мошенничество или кража;
</p>
<p>Б) Использовать Платежные услуги для любых коммерческих или иных целей, которые прямо не разрешены настоящей Платежной политикой;
</p>
<p>В) Использовать Платежные услуги для передачи, распространения, размещения или отправки любой информации о любом другом физическом или юридическом лице, включая, без ограничения, личную контактную информацию, номера кредитных, дебетовых, телефонных карт или счетов, или фотографии других лиц без их разрешения;
</p>
<p>Г) Регистрировать любой метод оплаты или метод вывода средств на вашем счете Adabstar, который не является вашим или вы не имеете разрешения на его использование;
</p>
<p>Д) Пытаться исследовать, сканировать или проверять уязвимость любой системы или сети Adabstar или нарушать любые меры безопасности или аутентификации;
</p>
<p>Е) Обходить, удалять, деактивировать, ухудшать, дескремблировать или иным образом обходить любые технологические меры, принятые Adabstar или любой другой третьей стороной (включая другого Участника) для защиты Платежных услуг;
</p>
<p>Ж) Подделывать любой заголовок пакета TCP/IP или любую часть информации заголовка в любой электронной почте или сообщении в группе новостей, или каким-либо образом использовать Платежные услуги для отправки измененной, обманчивой или ложной информации, идентифицирующей источник;
</p>
<p>З) Пытаться расшифровать, декомпилировать, дизассемблировать или провести реверс инжиниринг любого программного обеспечения, используемого для предоставления Платежных услуг;
</p>
<p>И) Использовать кредитные карты или другие способы оплаты обманным путем;
</p>
<p>К) Пропагандировать, поощрять или помогать третьим лицам делать что-либо из вышеперечисленного.
</p>
Adabstar имеет право расследовать и преследовать в судебном порядке нарушения любого из вышеперечисленных действий в полном соответствии с законом. Кроме того, как указано в настоящей Оферте, Adabstar может предпринять ряд действий против вас, включая, но не ограничиваясь, ограничение доступа к вашему Счету Adabstar и любым связанным с ним Платежным услугам, за нарушение настоящей Платежной политики.

<h2>10. РАЗНОГЛАСИЯ МЕЖДУ ПАРТНЕРОМ И ПОЛЬЗОВАТЕЛЕМ
</h2>
В случае разногласий между Партнером и Пользователем, Adabstar рекомендует Партнеру и Пользователю общаться напрямую и попытаться разрешить разногласия. Если Партнер и Пользователь не могут разрешить разногласия просят Adabstar выступить посредником, Adabstar может попросить Партнера и Пользователя предоставить записи общения по поводу спора, в которых должна быть указана вся основная информация, включая время, дату и детали общения. Хотя Adabstar попытается оценить спор на беспристрастной основе, Adabstar будет иметь право разрешить спор по своему усмотрению, и решение Adabstar по спору будет окончательным. В результате Adabstar имеет право по своему усмотрению:

Перевести кредиты, предложить возмещение или отменить транзакцию.
Определить, кто будет нести комиссионный сбор, возникающий в результате любых таких действий.

<h2>11. ОБРАЩЕНИЕ В СЛУЖБУ ПОДДЕРЖКИ КЛИЕНТОВ
</h2>
Если вы хотите сообщить о нарушении данной Оферты, у вас есть вопросы или вам нужна помощь, пожалуйста, свяжитесь со Службой поддержки клиентов Adabstar следующим образом:

Онлайн-поддержка: support@adabstar.ru.
      </div>
    </>
  );
};
