import React from "react";

export enum LogoType {
  DropDown = "DropDown",
  Plain = "Plain",
}

const Logo: React.FC<{ type?: LogoType }> = ({ type = LogoType.Plain }) => {
  return (
    <svg
      width={type === LogoType.DropDown ? "100" : "157"}
      height={type === LogoType.DropDown ? "12" : "25"}
      // viewBox="0 0 157 25"
      viewBox={type === LogoType.DropDown ? "0 0 200 25" : "0 0 157 25"}
     
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.73524 3.10889H8.04081L0 19.4152H2.18445L4.35471 15.0291L5.29121 13.1234L8.88801 5.90089L12.5143 13.1234L13.4213 15.0291L15.6504 19.4152H17.8643L9.73524 3.10889Z"
        fill="#23232B"
      />
      <path
        d="M44.0282 3.1084H42.3338L34.293 19.4147H36.4774L38.6477 15.0286L39.5842 13.1229L43.181 5.9004L46.8073 13.1229L47.7143 15.0286L49.9434 19.4147H52.1573L44.0282 3.1084Z"
        fill="#23232B"
      />
      <path
        d="M101.661 3.1084H99.9666L91.9258 19.4147H94.1103L96.2805 15.0286L97.217 13.1229L100.814 5.9004L104.44 13.1229L105.347 15.0286L107.576 19.4147H109.79L101.661 3.1084Z"
        fill="#23232B"
      />
      <path
        d="M31.917 5.5853C30.3363 4.0688 28.2529 3.31055 25.667 3.31055H18.6367V5.187H25.667C27.6485 5.187 29.229 5.76275 30.4083 6.91423C31.5873 8.06672 32.1768 9.58827 32.1768 11.4788C32.1768 13.3987 31.5971 14.9225 30.4377 16.0505C29.279 17.1778 27.6888 17.7414 25.667 17.7414H18.6367V19.6169H25.667C28.303 19.6169 30.3985 18.8785 31.9536 17.4017C33.509 15.9245 34.2868 13.96 34.2872 11.5081C34.2872 9.07606 33.4971 7.1018 31.917 5.5853Z"
        fill="#23232B"
      />
      <path
        d="M68.5029 12.0007C68.0565 11.2717 67.3974 10.6563 66.5255 10.1545C66.9961 9.84472 67.3756 9.41638 67.6253 8.91322C67.8899 8.37061 68.0224 7.77374 68.0119 7.17086C68.0119 5.83186 67.5983 4.81985 66.771 4.13482C65.9441 3.45119 64.7228 3.10905 63.1072 3.1084H53.4023V4.98488H63.2857C64.1776 4.98488 64.8438 5.1768 65.2845 5.56062C65.7259 5.94482 65.9464 6.52089 65.9461 7.28885C65.9461 8.01784 65.7181 8.57645 65.2622 8.96467C64.8066 9.35386 64.1478 9.54845 63.2857 9.54846H53.4023V11.424H64.0285C64.9599 11.424 65.6931 11.6999 66.2281 12.2518C66.7639 12.8033 67.0314 13.5468 67.0307 14.4822C67.0307 15.4269 66.7632 16.1727 66.2281 16.7196C65.6934 17.2661 64.9602 17.5393 64.0285 17.5393H53.4023V19.4148H63.6276C65.3312 19.4148 66.6813 18.9742 67.678 18.0929C68.6733 17.2116 69.1712 16.0177 69.1715 14.5113C69.1884 13.6289 68.9569 12.7593 68.5029 12.0007Z"
        fill="#23232B"
      />
      <path
        d="M69.7031 19.2889V17.4739C71.0302 17.4855 72.7564 17.4915 74.7762 17.4739C80.4524 17.4247 81.7429 17.2481 82.4873 16.264C83.2441 15.2635 83.2826 13.632 82.5887 12.7349C81.9215 11.8722 80.5723 11.6814 79.4918 11.5305C79.0622 11.4705 78.8658 11.4877 78.2433 11.447C75.5481 11.2709 73.7101 10.9741 72.7293 10.5566C72.067 10.2748 71.4588 9.93065 70.9904 9.22798C70.4544 8.42392 70.2965 7.18671 70.6539 6.08054C70.925 5.27088 71.444 4.56565 72.1382 4.06345C72.985 3.43828 73.8679 3.27738 75.0806 3.15597C77.6044 2.90329 85.2212 3.32256 82.6902 3.15597L84.9223 4.39071V4.97093C84.9139 4.97085 84.1701 4.96492 83.09 4.96196C82.4696 4.96027 80.5053 4.95528 79.2405 4.97093C75.3543 5.019 73.4 5.05975 72.8484 5.8784C72.3981 6.54664 72.4338 7.55128 72.8484 8.19751C73.4564 9.14519 74.7051 9.05028 77.6171 9.40747C81.1714 9.84345 82.9701 10.0855 84.0248 11.2661C85.151 12.5267 85.1295 14.2169 85.1252 14.5498C85.1219 14.8083 85.0727 16.668 83.8062 17.978C82.6267 19.1982 81.0419 19.2686 75.182 19.2888C73.8954 19.2933 72.0094 19.2967 69.7031 19.2889Z"
        fill="#23232B"
      />
      <path
        d="M86.0837 19.4151V4.9847H79.1875V3.10889H95.0459V4.9847H88.1348V19.4151H86.0837Z"
        fill="#23232B"
      />
      <path
        d="M112.778 12.2518C113.947 12.0451 114.888 11.5208 115.602 10.6788C116.315 9.83688 116.672 8.82487 116.672 7.64279C116.672 6.22511 116.216 5.11495 115.304 4.31234C114.392 3.50973 113.134 3.10841 111.529 3.1084L101.562 3.1558C101.749 3.76548 101.935 4.37518 102.122 4.98488H111.5C112.46 4.98488 113.206 5.2235 113.737 5.70076C114.267 6.17869 114.531 6.84587 114.531 7.70226C114.531 8.57849 114.267 9.24296 113.737 9.69568C113.206 10.1494 112.426 10.3759 111.395 10.3753H104.158V12.281H110.518L114.621 19.4147H116.88L112.778 12.2518Z"
        fill="#23232B"
      />
      <path
        d="M8.67401 16.0623C9.65464 16.0623 10.4496 15.2723 10.4496 14.2977C10.4496 13.3232 9.65464 12.5332 8.67401 12.5332C7.69339 12.5332 6.89844 13.3232 6.89844 14.2977C6.89844 15.2723 7.69339 16.0623 8.67401 16.0623Z"
        fill="#28D3CF"
      />
      <path
        d="M156.175 16.9871C156.173 17.1703 155.933 17.2369 155.837 17.0804C152.472 11.5619 149.107 6.04346 145.742 0.524992C145.648 0.370167 145.813 0.187526 145.976 0.26596L156.272 5.20507C156.24 9.1324 156.207 13.0597 156.175 16.9871Z"
        fill="#23232B"
      />
      <path
        d="M142.98 0.34696C143.143 0.265535 143.312 0.448819 143.217 0.604825C139.854 6.11908 136.492 11.6333 133.129 17.1476C133.033 17.3055 132.79 17.2359 132.792 17.051L132.906 5.36138L142.98 0.34696Z"
        fill="#23232B"
      />
      <path
        d="M155.523 19.2841C155.717 19.2833 155.778 19.5455 155.604 19.6301L144.538 25L133.459 19.7224C133.284 19.6391 133.343 19.3767 133.536 19.3759L135.374 19.3682L135.379 19.3583L153.675 19.282L153.681 19.2918L155.523 19.2841Z"
        fill="#23232B"
      />
      <path
        d="M150.288 12.4111H138.617L144.428 2.85693L150.288 12.4111Z"
        fill="#28D3CF"
      />
      <path
        d="M153.152 17.0984L135.695 17.2129L137.403 14.3872L138.703 12.2197H150.208L151.469 14.3872L153.152 17.0984Z"
        fill="#28D3CF"
      />
      <path
        d="M144.401 13.3373C145.381 13.3373 146.176 12.5202 146.176 11.5124C146.176 10.5045 145.381 9.6875 144.401 9.6875C143.42 9.6875 142.625 10.5045 142.625 11.5124C142.625 12.5202 143.42 13.3373 144.401 13.3373Z"
        fill="white"
      />
      <path
        d="M43.2756 16.0623C44.2562 16.0623 45.0512 15.2723 45.0512 14.2977C45.0512 13.3232 44.2562 12.5332 43.2756 12.5332C42.295 12.5332 41.5 13.3232 41.5 14.2977C41.5 15.2723 42.295 16.0623 43.2756 16.0623Z"
        fill="#28D3CF"
      />
      <path
        d="M100.904 16.0623C101.885 16.0623 102.68 15.2723 102.68 14.2977C102.68 13.3232 101.885 12.5332 100.904 12.5332C99.9239 12.5332 99.1289 13.3232 99.1289 14.2977C99.1289 15.2723 99.9239 16.0623 100.904 16.0623Z"
        fill="#28D3CF"
      />
    </svg>
  );
};

export { Logo };
