import React from "react";
import { translations } from "../../../../../i18n";
import { t } from "i18next";
import './styles.scss';
import ExampleVideo from "./media/video.mp4";
import { Video } from "../../../../Extra/Video";


export class WelcomeScreen extends React.Component {
  render() {
    return (
      <div className="welcomeBlock">
        <div className="mainScreen">
          <div className="infoBlock">
            <div className="title">
              <span className="colored">{t(translations.welcomeScreen.infoBlock.title.firstPart)}</span>
              <span>{t(translations.welcomeScreen.infoBlock.title.secondPart)}</span>
            </div>
            <div className="subtitle">
              <span className="colored">{t(translations.welcomeScreen.infoBlock.subtitle.firstPart)}</span>
              <span>{t(translations.welcomeScreen.infoBlock.subtitle.secondPart)}</span>
            </div>
            <div className="loginBlock">
              <div className="loginButton">
                <a
                  className="loginLink"
                  href="https://www.ya.ru" //Потом поменять на экран с регистрацией
                >
                  {t(translations.welcomeScreen.loginBlock.loginButton)}
                </a>
              </div>
              <div className="loginInfo">{t(translations.welcomeScreen.loginBlock.loginInfo)}</div>
            </div>
          </div>
          <div className="videoBlock">
            <Video
              muted={true}
              autoPlay={true}
              loop={true}
              src={ExampleVideo}
            />
          </div>
        </div>
      </div>
    );
  }
};