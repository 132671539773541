import React from "react";
import moment from "moment";
import { MainView } from "../../Components/Layout/Common/MainView";
import "./style.scss";
import { Video } from "../../Components/Extra/Video";
import api from "../../Utils/publicApi";
import Loader from '../../Components/Layout/Common/Loader/Loader';
import { Paths, withRouter } from "../../Utils";
import { Heading3, Heading4 } from "../../Components/Headings";
import {
    PrimaryButton,
    Secondary1Button,
    Secondary2Button,
} from "../../Components/Buttons";
import { Tag } from "../../Components/Extra/Tag";
import Popup from "../../Components/Layout/Common/Popup";
import { SingleDropdown } from "../../Components/Dropdowns/Single";
import {
    dataToDropdownOptions,
    DropdownOption,
} from "../../Components/Dropdowns/Common/types";
import Calendar from "../../Components/Calendar";
import { Logo } from "../../Components/Layout/Common/Logo/Logo";

interface SpecialistInfoProps {
    specialist: any;
    extra: any;
}

class SpecialistInfo extends React.Component<SpecialistInfoProps, {}> {
    formatName(specialist: any) {
        const names = [
            specialist.firstName,
            specialist.middleName,
            specialist.lastName,
        ];

        return names.filter((e) => e !== null).join(" ");
    }

    render() {
        let name = "";
        let profession = "";
        let desc = [];

        if (this.props.specialist && this.props.extra) {
            const specialist = this.props.specialist;
            name = this.formatName(specialist);

            const professions: any[] = this.props.extra.professions;

            const specialistProfessions = professions.filter((p) =>
                specialist.professions.includes(p.id)
            );

            profession = specialistProfessions
                .map((sp) => {
                    return sp.name;
                })
                .slice(0, 3)
                .join(", ");

            desc = specialist.about ? specialist.about.split("\n") : [];
        }

        return (
            <div className="specialist-card-info">
                <div className="specialist-card-info-name">
                    <Heading3>{name}</Heading3>
                </div>
                <div className="specialist-card-info-profession">
                    {profession}
                </div>
                <div className="specialist-card-info-description">
                    {desc.map((e: any) => {
                        return (
                            <span>
                                {e}
                                <br />
                            </span>
                        );
                    })}
                </div>
            </div>
        );
    }
}

interface SpecialistCardItemProps {
    title: any;
    dictionary: any[];
    values: any;
    addClass?: string;
}

class SpecialistCardItem extends React.Component<SpecialistCardItemProps, {}> {
    render() {
        const valuesNames: string[] = this.props.dictionary
            .filter((d: any) => this.props.values.includes(d.id))
            .map((d: any) => d.name);

        return (
            <div className={`specialist-card-item ${this.props.addClass ? this.props.addClass : ''}`}>
                <Heading4>
                    <span className="specialist-card-heading">
                        {this.props.title}
                    </span>
                </Heading4>
                <div className="specialist-card-item-list">
                    {valuesNames.map((n) => {
                        return <Tag>{n}</Tag>;
                    })}
                </div>
            </div>
        );
    }
}

interface SpecialistCardServicesListProps {
    services: any;
    addClass?: string;
}

class SpecialistCardServicesList extends React.Component<
    SpecialistCardServicesListProps,
    {}
> {
    render() {
        const services = this.props.services;
        return (
            <div className={`specialist-card-item ${this.props.addClass ? this.props.addClass : ''}`}>
                <Heading4>
                    <span className="specialist-card-heading">
                        Список услуг
                    </span>
                </Heading4>
                <div className="specialist-card-services-list">
                    {services.map((s: any) => {
                        return (
                            <div className="specialist-card-services-list-element">
                                <div className="specialist-card-services-list-element-service">
                                    <span className="specialist-card-services-list-element-service-name">{s.name}</span>
                                    {
                                        s.description &&
                                        <span className="specialist-card-services-list-element-service-description">{s.description}</span>
                                    }
                                </div>
                                <div className="specialist-card-service-price">
                                    <span>
                                        {s.price}
                                        {`${" РУБ."}`}
                                    </span>
                                    {s.discountSize > 0 && (
                                        <div className="specialist-card-service-discount">
                                            -{s.discountSize}
                                            {`${s.discountUnitId === 1
                                                ? "%"
                                                : " РУБ"
                                                }`}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

interface SpecialistProps {
    params: any;
}

interface SpecialistState {
    loading: boolean;
    specialist: any;
    services: any[];
    extra: any;
    chosenServiceId: any;
    chosenDate: moment.Moment;
    chosenSlot: any;
    timeSlots: DropdownOption[];
    popupOpened: boolean;
    resultPopupOpened: boolean;
    resultTitle: string;
    resultSubtitle: string;
}

class Specialist extends React.Component<SpecialistProps, SpecialistState> {
    constructor(props: SpecialistProps) {
        super(props);

        this.state = {
            loading: true,
            specialist: null,
            services: [],
            extra: null,
            chosenServiceId: null,
            chosenDate: moment(),
            timeSlots: [],
            chosenSlot: null,
            popupOpened: false,
            resultPopupOpened: false,
            resultTitle: "Что-то пошло не так :(",
            resultSubtitle: "Сообщение о том, почему что-то пошло не так",
        };
    }

    async componentDidMount() {
        this.setState({
            loading: true,
        });

        const id = this.props.params.id;

        const specialist = await api.getCatalogCard(id);
        const extra = await api.getPlatformExtra();

        let services: DropdownOption[] = [];
        if (specialist.services) {
            const originalServices = JSON.parse(
                JSON.stringify(specialist.services)
            );
            services = dataToDropdownOptions(
                originalServices.map((s: any) => {
                    s.name = `${s.name} - ${s.price} AC`;
                    return s;
                })
            );
        }

        this.setState({
            specialist: specialist,
            services: services,
            extra: extra,
            loading: false,
        });
    }

    async handlePreAppointment() {
        const profile = await api.getProfileShort();

        let authenticated = true;
        if (profile.success === false || profile.unauthenticated === true) {
            authenticated = false;
        }

        if (authenticated) {
            this.openClosePopup(true);
        }
        else {
            window.open(
                `${process.env.REACT_APP_UAF_HOST}?redirect=${window.location}`,
                "_self"
            );
        }
    }

    openClosePopup(forced?: boolean) {
        let opened = !this.state.popupOpened;

        if (forced !== undefined) {
            opened = forced;
        }

        if (opened) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        this.setState({
            chosenServiceId: null,
            popupOpened: opened,
        });
    }

    openCloseResultPopup(forced?: boolean) {
        let opened = !this.state.resultPopupOpened;

        if (forced !== undefined) {
            opened = forced;
        }

        this.setState({
            resultPopupOpened: opened,
        });
    }

    selectAppointmentService(serviceId: number) {
        this.setState({
            chosenServiceId: serviceId,
        });
    }

    async selectAppointmentDate(date: moment.Moment) {

        const fromRaw = moment(date.format("YYYY-MM-DD"));
        const from = fromRaw.unix();
        const toRaw = fromRaw.add(1, "days");
        const to = toRaw.unix() - 1;
        const scheduleSlotsResult = await api.getScheduleSlots(this.state.specialist.id, from, to)

        if(scheduleSlotsResult.success === false)
        {
            return;
        }

        const slots = scheduleSlotsResult.schedulesSlots;

        const rawSlots = [];

        for(const slot of slots)
        {
            if(slot.available && slot.from > moment().unix())
            {
                rawSlots.push({
                    id: slot.from,
                    name: moment(slot.from * 1000).format("HH:mm"),
                })
            }
        }

        const timeSlots = dataToDropdownOptions(rawSlots);



        this.setState({
            chosenSlot: null,
            chosenDate: date,
            timeSlots: timeSlots,
        });
    }

    selectAppointmentTime(slot: number) {
        this.setState({
            chosenSlot: slot,
        });
    }

    async makeAppointment() {
        try {
            const result = await api.createServiceRequest({
                profileId: this.state.specialist.id,
                serviceId: this.state.chosenServiceId,
                serviceDate: this.state.chosenSlot
            });

            console.log(result, result.paymentOrderUrl)
    
            if (result._serviceInfo && result._serviceInfo.statusText && result._serviceInfo.statusText === "OK" && result.paymentOrderUrl) {
                new URL(result.paymentOrderUrl);
                window.open(result.paymentOrderUrl, "_self", "noopener");
            } else {
                this.setState({
                    popupOpened: false,
                    resultPopupOpened: true,
                    resultTitle: "Что-то пошло не так :(",
                    resultSubtitle: "Попробуйте еще раз",
                })
            }
        } catch(e) {
            console.log(e);
            
            this.setState({
                popupOpened: false,
                resultPopupOpened: true,
                resultTitle: "Произошла непредвиденная ошибка :(",
                resultSubtitle: "Попробуйте еще раз позднее",
            })
        }
    }

    render() {
        const specialist = Object.assign({}, this.state.specialist);

        let services = this.state.services;
        const timeSlots = this.state.timeSlots;

        const resultTitle = this.state.resultTitle;
        const resultSubtitle = this.state.resultSubtitle;

        return (
            <MainView>
                {
                    this.state.loading ?
                        <Loader />
                        : (
                            <>
                                <div className="specialist-card-title">
                                    <div className="specialist-card-title-text">
                                        Карточка консультанта
                                    </div>
                                </div>
                                <div className="specialist-card-grid">
                                    <div className="person">
                                        {
                                            specialist.video && specialist.video.path && specialist.video.path.length > 0 &&
                                            <Video
                                                controls
                                                // poster="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
                                                src={specialist.video.path} />
                                        }
                                        {
                                            !specialist.video && specialist.avatar && specialist.avatar.medium && specialist.avatar.large &&
                                            <div className='photo'>
                                                <div>
                                                    <img style={{ objectFit: "fill", filter: "blur(5px)", transform: "scale(1.1)" }} src={specialist.avatar.medium} alt="" />
                                                    <img src={specialist.avatar.large} alt="" />
                                                </div>
                                            </div>
                                        }
                                        {
                                            !specialist.video && !specialist.avatar &&
                                            <div className="video-stub">
                                                <Heading4>Видео отсутствует :(</Heading4>
                                                <Logo></Logo>
                                            </div>
                                        }
                                    </div>
                                    <div className="specialist-card">
                                        <div className="specialist-card-item">
                                            <SpecialistInfo
                                                extra={this.state.extra}
                                                specialist={this.state.specialist}
                                            />
                                        </div>
                                    </div>
                                    <div className="specialist-card-appointment">
                                        <div className="specialist-card-appointment-button">
                                            <PrimaryButton
                                                onClick={() =>
                                                    this.handlePreAppointment()
                                                }
                                            >
                                                Записаться к консультанту
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                    <div className="specialist-card-services">
                                        <SpecialistCardServicesList
                                            addClass={'service-list'}
                                            services={specialist.services}
                                        />
                                    </div>
                                    <div className="specialist-card-tags">
                                        <SpecialistCardItem
                                            title="Список тэгов"
                                            dictionary={this.state.extra.tags}
                                            values={specialist.tags}
                                            addClass={"specialis-tags"}
                                        />
                                    </div>
                                    <div className="specialist-card-spec">
                                        <SpecialistCardItem
                                            title="Специализация"
                                            dictionary={this.state.extra.activitySpheres}
                                            values={specialist.activitySpheres}
                                            addClass={"specialis-spec"}
                                        />
                                    </div>
                                    {/* <div className="specialist-card-loc">
                                        <SpecialistCardItem
                                            title="Локация"
                                            dictionary={[
                                                { id: 1, name: "Россия" },
                                            ]}
                                            values={[1]}
                                        />
                                    </div>
                                    <div className="specialist-card-lang">
                                        <SpecialistCardItem
                                            title="Владение языками"
                                            dictionary={[
                                                { id: 1, name: "Русский" },
                                            ]}
                                            values={[1]}
                                        />
                                    </div> */}
                                </div>
                                <Popup
                                    title="Заказать услугу"
                                    onClick={() => this.openClosePopup(false)}
                                    active={this.state.popupOpened}
                                >
                                    <Heading4>Выберите услугу</Heading4>
                                    <div className="appointment-popup-element">
                                        <SingleDropdown
                                            title="Не выбрано"
                                            options={services}
                                            onChange={(serviceIds) =>
                                                this.selectAppointmentService(
                                                    serviceIds[0]
                                                )
                                            }
                                        />
                                    </div>
                                    {this.state.chosenServiceId && (
                                        <>
                                            <Heading4>Выберите дату</Heading4>
                                            <div className="appointment-popup-element">
                                                <Calendar
                                                    onChange={(date) =>
                                                        this.selectAppointmentDate(date)
                                                    }
                                                    StartYear={new Date().getFullYear()}
                                                    ShowButton={true}
                                                />
                                            </div>
                                            {timeSlots.length > 0 ? (
                                                <>
                                                    <Heading4>Выберите время</Heading4>
                                                    <span>
                                                        Время указано в вашей текущей
                                                        временной зоне{" "}
                                                        {moment().format("(UTC Z)")}
                                                    </span>
                                                    <div className="appointment-popup-element">
                                                        <SingleDropdown
                                                            title="Не выбрано"
                                                            options={timeSlots}
                                                            onChange={(slot) =>
                                                                this.selectAppointmentTime(
                                                                    slot[0]
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    {this.state.chosenSlot && (
                                                        <>
                                                            <div className="appointment-popup-element">
                                                                <div className="appointment-popup-info">
                                                                    Вы записываетесь на{" "}
                                                                    {this.state.chosenDate.format(
                                                                        "DD.MM.YYYY"
                                                                    )}{" "}
                                                                    в{" "}
                                                                    {moment(
                                                                        this.state
                                                                            .chosenSlot *
                                                                        1000
                                                                    ).format(
                                                                        "HH:mm"
                                                                    )}{" "}
                                                                    для получения услуги{" "}
                                                                    {
                                                                        specialist.services.find(
                                                                            (s: any) =>
                                                                                s.id ===
                                                                                this
                                                                                    .state
                                                                                    .chosenServiceId
                                                                        ).name
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="appointment-popup-element">
                                                                <PrimaryButton
                                                                    onClick={() => this.makeAppointment()}
                                                                >
                                                                    Записаться к
                                                                    консультанту
                                                                </PrimaryButton>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <div className="appointment-popup-element">
                                                    <div className="appointment-popup-info">
                                                        На выбранную дату у специалиста
                                                        нет свободных временных слотов
                                                        :(
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </Popup>
                                <Popup
                                    onClick={() => this.openCloseResultPopup(false)}
                                    active={this.state.resultPopupOpened}
                                >
                                    <Heading3>
                                        <span className="appointment-result-popup-title">
                                            {resultTitle}
                                        </span>
                                    </Heading3>
                                    <span className="appointment-result-popup-subtitle">
                                        {resultSubtitle}
                                    </span>
                                    <div className="appointment-result-popup-buttons">
                                        <div className="appointment-result-popup-element">
                                            <Secondary1Button onClick={() => { window.open(Paths.Root, "_self") }}>
                                                Перейти на главную
                                            </Secondary1Button>
                                        </div>
                                        <div className="appointment-result-popup-element">
                                            <Secondary2Button onClick={() => { window.open(process.env.REACT_APP_PA_HOST, "_self") }}>
                                                Личный кабинет
                                            </Secondary2Button>
                                        </div>
                                        <div className="appointment-result-popup-element">
                                            <PrimaryButton onClick={() => { window.open(Paths.Catalog, "_self") }}>
                                                Каталог специалистов
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                </Popup>
                            </>
                        )
                }
            </MainView>
        );
    }
}

export default withRouter(Specialist);
