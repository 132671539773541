import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { translations } from "../../../../../i18n";
import { StartTestButton } from "./StartTestButton";
import { road } from "./content";
import cn from "classnames";
import classes from "./HowWorks.module.scss";

const TabSolo = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState(0);

  const handleHover = (current: number) => {
    setActive(current);
  };

  return (
    <div className={classes.tabContent}>
      <h4
        dangerouslySetInnerHTML={{
          __html: t(translations.howWorks.title2),
        }}
      />
      <div className={classes.roadMap}>
        {new Array(3).fill(1).map((item, index) => {
          return (
            <div key={`road${index}`} className={classes.roadMapItem}>
              <div
                className={cn(
                  classes.roadMapPoint,
                  active >= index && classes.pointActive
                )}
              />
              <div className={classes.roadMapRoute} />
            </div>
          );
        })}
      </div>

      <div className={classes.soloRoad}>
        {road.map((item) => {
          return (
            <div
              key={`road${item.id}`}
              className={classes.roadItem}
              onMouseOver={() => handleHover(item.id)}
            >
              <h4>
                {t(translations.howWorks.stepNumber, { step: item.id + 1 })}
              </h4>
              <h5>{t(item.title)}</h5>
              <p>{t(item.description)}</p>
            </div>
          );
        })}
      </div>
      {/* <StartTestButton /> */}
    </div>
  );
};

export { TabSolo };
