import api from "./api";

async function apiRequest(type: string, method: string, body: any, headers: any, additionalOptions: any)
{
    const requestOptions = {
        withCredentials: true
    };

    const url = `${process.env.REACT_APP_PUBLIC_HOST}/public${method}`;
    let result = await api.request(type, url, body, headers, requestOptions);

    if(result._serviceInfo.status >= 400)
    {
        switch (result._serviceInfo.status) {
            case 403: 
                console.log("FORBIDDEN!"); //TODO: Что-то сделать нужно с этим
                break;
            case 401:
                result = {
                    unauthenticated: true
                }
                break;
            default:
                console.log(result);
                break;
        }
    }

    return result;
}

function handleUnauthenticated()
{
    if(process.env.REACT_APP_NODE_ENV === 'production')
    {
        window.open(process.env.REACT_APP_AUTH_PAGE + "/?redirect=" + process.env.REACT_APP_PA_HOST, "_self"); //TODO: Заменить на текущий URL
    }
    else
    {
        window.open("/unauth", "_self");
    }
}

async function getCatalog(filter: any = {}, pagination: any = {}) {
    const { limit, offset } = pagination;

    return await apiRequest('post', `/catalog?limit=${limit}&offset=${offset}`, filter, {}, {});
}

async function getCatalogCard(id: any) {
    return await apiRequest('get', `/catalog/${id}`, {}, {}, {});
}

async function getPlatformExtra() {
    return await apiRequest('get', '/extra/platform', {}, {}, {});
}

async function getProfile() {
    return await apiRequest('get', '/profile', {}, {}, {});
}

async function getProfileShort() {
    return await apiRequest('get', '/profile/short', {}, {}, {});
}

async function createServiceRequest(body: any) {
    return await apiRequest('post', '/services-requests', body, {}, {});
}

async function getScheduleSlots(cardId: number, from: number, to: number) {
    return await apiRequest('get', `/catalog/${cardId}/schedule?from=${from}&to=${to}`, {}, {}, {});
}

async function getOperation(operationId: number) {
    return await apiRequest('get', `/operations/${operationId}`, {}, {}, {});
}

const paApiHelper = {
    handleUnauthenticated,
    getCatalog,
    getProfile,
    getProfileShort,
    getPlatformExtra,
    getCatalogCard,
    createServiceRequest,
    getScheduleSlots,
    getOperation,
}

export default paApiHelper;