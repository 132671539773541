import React from "react";
import "./style.scss";
import "../Common/style.scss";

import { DropdownProps, DropdownState } from "../Common/types";
import Plus from "../../../Media/Icons/plus.svg";
import Minus from "../../../Media/Icons/minus.svg";
import { Heading4 } from "../../Headings";
import { Radio } from "../../Extra/Radio";
import { outsideClickHandler } from "../../../Utils";

export class SingleDropdown extends React.Component<
    DropdownProps,
    DropdownState
> {

    refControls: any = null;
    constructor(props: DropdownProps) {
        super(props);

        this.state = {
            opened: false,
            chosenValues: props.preselected || [],
        };

        this.refControls = React.createRef();
    }

    selected(id: number) {
        return this.state.chosenValues.includes(id);
    }

    select(id: number) {
        this.setState({
            opened: !this.state.opened,
            chosenValues: [id],
        });
        
        if(this.props.onChange)
        {
            this.props.onChange([id]);
        }
    }

    openClose(forced?: boolean) {
        let opened = !this.state.opened;

        if (forced !== undefined) {
            opened = forced;
        }

        this.setState({
            opened: opened,
        });
    }

    componentDidMount() {
        outsideClickHandler({
            bindObj: this,
            body: document,
            ref: this.refControls,
            action: (() => this.openClose(false)),
            type: "click",
            condition: !(this.state.opened)
        })
    }

    render() {
        const options = this.props.options;
        let title: any = this.props.title || "Выберите...";

        if (this.state.chosenValues && this.state.chosenValues[0]) {
            const chosen = this.state.chosenValues;
            title = options.find((o) => o.id === chosen[0])?.title;
        }

        return (
            <div ref={this.refControls} className="dropdown">
                <div
                    className="dropdown-info"
                    onClick={() => this.openClose()}
                >
                    <div className="dropdown-info-option">
                        {<Heading4>{title}</Heading4>}
                    </div>
                    <div
                        className="dropdown-info-button"
                    >
                        {!this.state.opened ? (
                            <img src={Plus} alt="+" />
                        ) : (
                            <img src={Minus} alt="-" />
                        )}
                    </div>
                </div>
                {this.state.opened && (
                    <div className="dropdown-list-wrapper">
                        {options.map((o) => {
                            return (
                                <div 
                                    className="dropdown-list-option"
                                    onClick={() => this.select(o.id)}
                                >
                                    <span>{o.title}</span>
                                    <Radio
                                        checked={this.selected(o.id)}
                                        onChange={() => this.select(o.id)}
                                        id={String(o.id)}
                                        name="service"
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}
