import React from "react";
import { Logo } from "./Logo";
import { FacebookIcon } from "./FacebookIcon";
import { InstIcon } from "./InstIcon";
import classes from "./Footer.module.scss";
import { t } from "i18next";
import { translations } from "../../../../i18n";

const Footer = () => {
  return (
    <div className={classes.footer}>
      <div className={classes.wrapper}>
        <div className={classes.leftBlock}>
          <Logo />
          <p
            dangerouslySetInnerHTML={{
              __html: t(translations.footer.title),
            }}
          />
        </div>
        <div className={classes.centerBlock}>
          <ul>
            <li>
              <a href="/information/terms">{"Политика конфиденциальности"}</a>
            </li>
            <li>
              <a href="/information/offer">{"Публичная оферта"}</a>
            </li>
            <li>
              <a href="/information/agreement">{"Пользовательское соглашение"}</a>
            </li>
          </ul>
        </div>

        <div className={classes.rightBlock}>
          <ul>
            <li>
              <a href="/specialists">{"Каталог карьерных консультантов"}</a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_PA_HOST}`}>{"Личный кабинет"}</a>
            </li>
          </ul>
          <div className={classes.socialIcons}>
          </div>
        </div>
      </div>
      <div className={classes.bottomBlock}>
        {/* <p>{t(translations.footer.policy)}</p> */}
        <p>{`© ${new Date().getFullYear()} ${t(translations.footer.copyright)}`}</p>
      </div>
    </div>
  );
};

export { Footer };
