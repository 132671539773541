import React from "react";
import "./style.scss";

interface TagProps {
    checked?: boolean;
}

export class Tag extends React.Component<TagProps, {}> {
    
    render() {
        const checked = this.props.checked || false;
        return (
            <div className={`tag ${checked ? "checked" : ""}`}>{this.props.children}</div>
        );
    }
};
