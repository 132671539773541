import React from "react";
import { Carousel, Progress as AntProgress } from "antd";
import { content } from "./content";
import { translations } from "../../../../../i18n";
import { t } from "i18next";
import classes from "./Case.module.scss";
import { CarouselRef } from "antd/lib/carousel";
import { elementPartlyVisible } from "../../../../../Utils";

interface Props {
  img: string;
  name: string;
  labels: Array<string>;
  text: any;
}

const SlideItem: React.FC<Props> = ({ img, name, labels, text }) => {
  return (
    <div className={classes.caseItem}>
      <div className={classes.clientInfo}>
        <img src={img} alt="user" />
        <div className={classes.clientName}>
          <span>{name}</span>
        </div>
        <div className={classes.clientLabels}>
          {labels.map((item) => {
            return <span key={`case_labels${item}`}>{item}</span>;
          })}
        </div>
      </div>
      <div className={classes.clientReview}>
        <p>{text}</p>
      </div>
    </div>
  );
};


interface Arrow {
  onClick: any;
  arrowType: string;
  active: boolean;
}

interface State {
  current_slide: number;
  scroll_count: number;
  slide_show: number;
  visible: boolean;
}


const Arrow: React.FC<Arrow> = ({ onClick, arrowType, active }) => {
  return (
    <>
      <div
        className={`${arrowType} ${active ? classes.active : classes.inactive}`}
        onClick={onClick}
      />
    </>
  )
}


export class Case extends React.Component<{}, State> {
  sliderRef: React.RefObject<CarouselRef>;
  case_block: React.RefObject<any>;

  constructor(props: Props) {
    super(props)
    this.state = {
      current_slide: 1,
      scroll_count: 1,
      slide_show: 1,
      visible: false
    }

    this.sliderRef = React.createRef();
    this.case_block = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("scroll", this.onScroll, true);
    if (this.isAnchorVisible()) {
      this.setState({ visible: true });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.onScroll, true);
  }

  onScroll = () => {
    if (this.isAnchorVisible()) {
      this.setState({ visible: true });
    }
  }

  isAnchorVisible = () => {
    const { current } = this.case_block;
    return current && elementPartlyVisible(current);
  }

  onChange = (from: number, to: number) => {
    if (from !== to) {
      if (from < to) {
        if (this.state.current_slide !== content.length) {
          this.setState({ current_slide: this.state.current_slide + this.state.scroll_count });
        }
      } else {
        if (this.state.current_slide !== this.state.slide_show) {
          this.setState({ current_slide: this.state.current_slide - this.state.scroll_count });
        }
      }
    }
  };

  render() {
    const settings = {
      slidesToScroll: this.state.scroll_count,
      slidesToShow: this.state.slide_show,
      arrows: true,
      draggable: true,
      prevArrow: (
        <Arrow
          active={this.state.current_slide === this.state.slide_show ? false : true}
          onClick={onclick}
          arrowType={classes.caseLeftArrow}
        />
      ),
      nextArrow: (
        <Arrow
          active={this.state.current_slide === content.length ? false : true}
          onClick={onclick}
          arrowType={classes.caseRightArrow}
        />
      ),
    };

    return (
      <div
        className={`${classes.case} ${this.state.visible ? classes.visible : classes.invisible}`}
        ref={this.case_block}
      >
        <h3 className={classes.caseTitle}>
          {
            t(translations.case.mainTitle)
          }
        </h3>
        <span className={classes.indicator}>
          <span className={classes.colored}>{this.state.current_slide} </span> / {content.length}
        </span>
        <AntProgress
          className={classes.progress}
          type="line"
          percent={(this.state.current_slide * 100) / content.length}
          showInfo={false}
          strokeColor="#23232B"
          strokeWidth={2}
        />
        <Carousel
          beforeChange={this.onChange}
          infinite={false}
          {...settings}
          className={classes.caseSlider}
          ref={this.sliderRef}
        >
          {content.map((item) => {
            return (
              <SlideItem
                key={`case${item.id}`}
                img={item.img}
                name={item.name}
                labels={item.labels}
                text={item.text}
              />
            );
          })}
        </Carousel>
      </div>
    )
  }
}