import React from "react";
import { Terms } from "../../Localization/ru_RU/Terms";
import { MainView } from "../../Components/Layout/Common/MainView";


export class TermsView extends React.Component {
    render() {
        return <MainView>
            <Terms/>
        </MainView>
    }
} 