import moment from "moment";
import { useParams } from "react-router-dom";
// import 'moment/locale/ru';
// import { DropdownItem } from '../Components/Dropdowns/Select/types';

// // const time = new Date().toString();

// // console.log(`${typeof(time)}: ${time}`);

// const createTimeForTableFeedback = (dataStr: string) => {
//     const date = moment(dataStr).format('L');
//     const time = moment(dataStr).format('LT');
//     const dateTime = `${date}-${time}`;
//     return dateTime;
// };

interface CommondHandlerOptions {
    bindObj: any,
    body: Document,
    ref: any,
    action: Function,
    type: string,
    condition: boolean
}
/**
 * Хендлер вызова переданной функции @action при клике @event вне элемента @ref
 * @param {any} bindObj - точка вызова хендлера (по умолчанию передавать this)
 * @param {Document} body - ReactDOM, в котором происходит обработка события (по умолчанию передавать document)
 * @param {any} ref - элемент, вне которого проверяется клик
 * @param {Function} action - обработчик, который нужно вызвать при клике вне компонента @ref
 * @param {string} type - тип обрабатываемого события (по умолчанию передавать "click")
 * @param {boolean} condition - условие добавления события (при каком condition нужно добавить обработчик события на @body)
 */
export function outsideClickHandler(options: CommondHandlerOptions) {
    const {
        bindObj,
        body,
        ref,
        action,
        type,
        condition
    } = options;
    if (condition) {
        body.addEventListener(type, (event: any) => handleOutsideEvent(event, ref, action.bind(bindObj)), { capture: true })
    }
    else {
        body.removeEventListener(type, (event: any) => handleOutsideEvent(event, ref, action.bind(bindObj)), { capture: true })
    }
}

function handleOutsideEvent(event: any, ref: any, action: Function) {
    if (!ref || !ref.current) {
        return;
    }

    if (!ref.current.contains(event.target)) {
        action();
    }
}

export function elementPartlyVisible(element: any) {
    const rect = element.getBoundingClientRect();

    return (
        rect.top < window.innerHeight && rect.bottom >= 0
    );
}

// async function delay(ms: number) {
//     return new Promise(resolve => setTimeout(resolve, ms));
// }

// function mapArrayToDropdownElements(array: any[], elementNameKey: string = "name") {
//     const items: DropdownItem[] = array.map((element: any) => {
//         const item: DropdownItem = {
//             id: element.id,
//             label: element[elementNameKey],
//             data: element,
//         };
//         return item;
//     });

//     return items;
// }

// export {
//     createTimeForTableFeedback,
//     outsideClickHandler,
//     delay,
//     mapArrayToDropdownElements,
// };

// // console.log(createTimeForTableFeedback(time));

export enum Paths {
    Root = "/",
    Catalog = "/specialists",
    Consultant = "/specialists/:id",
    // Auth = authHost,
    SuccessRegistration = "/verification/success",
    ErrorRegistration = "/verification/error",
    Error = "/error",
}

export const withRouter = (WrappedComponent: any) => (props: any) => {
    const params = useParams();
    // etc... other react-router-dom v6 hooks

    return (
        <WrappedComponent
            {...props}
            params={params}
        // etc...
        />
    );
};