import React from "react";

const Logo = () => {
  return (
    <svg
      width="251"
      height="40"
      viewBox="0 0 251 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5763 4.97266H12.8652L0 31.0626H3.49509L6.96749 24.0448L8.46587 20.9957L14.2207 9.43983L20.0227 20.9957L21.4739 24.0448L25.0405 31.0626H28.5827L15.5763 4.97266Z"
        fill="white"
      />
      <path
        d="M70.4474 4.97266H67.7363L54.8711 31.0626H58.3662L61.8386 24.0448L63.337 20.9957L69.0919 9.43983L74.8938 20.9957L76.345 24.0448L79.9116 31.0626H83.4538L70.4474 4.97266Z"
        fill="white"
      />
      <path
        d="M162.654 4.97266H159.943L147.078 31.0626H150.573L154.046 24.0448L155.544 20.9957L161.299 9.43983L167.101 20.9957L168.552 24.0448L172.119 31.0626H175.661L162.654 4.97266Z"
        fill="white"
      />
      <path
        d="M51.0687 8.93499C48.5394 6.5086 45.2061 5.29541 41.0687 5.29541H29.8203V8.29771H41.0687C44.2391 8.29771 46.7678 9.2189 48.6547 11.0613C50.5411 12.9052 51.4843 15.3397 51.4843 18.3646C51.4843 21.4363 50.5568 23.8745 48.7018 25.6792C46.8479 27.4829 44.3035 28.3847 41.0687 28.3847H29.8203V31.3854H41.0687C45.2862 31.3854 48.639 30.2039 51.1271 27.841C53.6158 25.4776 54.8603 22.3343 54.8609 18.4114C54.8609 14.5202 53.5968 11.3614 51.0687 8.93499Z"
        fill="white"
      />
      <path
        d="M109.606 19.2002C108.892 18.0338 107.837 17.0492 106.442 16.2463C107.195 15.7507 107.802 15.0654 108.202 14.2603C108.625 13.3921 108.837 12.4372 108.82 11.4725C108.82 9.33016 108.159 7.71096 106.835 6.61491C105.512 5.52112 103.558 4.9737 100.973 4.97266H85.4453V7.975H101.259C102.686 7.975 103.752 8.28207 104.457 8.89618C105.163 9.51089 105.516 10.4326 105.515 11.6613C105.515 12.8277 105.15 13.7215 104.421 14.3426C103.692 14.9653 102.638 15.2767 101.259 15.2767H85.4453V18.2775H102.447C103.937 18.2775 105.11 18.719 105.966 19.602C106.824 20.4845 107.252 21.674 107.251 23.1705C107.251 24.6822 106.822 25.8755 105.966 26.7504C105.111 27.6248 103.938 28.062 102.447 28.062H85.4453V31.0627H101.806C104.531 31.0627 106.692 30.3577 108.286 28.9476C109.879 27.5376 110.675 25.6274 110.676 23.2172C110.703 21.8053 110.332 20.414 109.606 19.2002Z"
        fill="white"
      />
      <path
        d="M111.523 30.8612V27.9573C113.647 27.9758 116.409 27.9853 119.64 27.9573C128.722 27.8784 130.787 27.5959 131.978 26.0213C133.189 24.4207 133.25 21.8102 132.14 20.3748C131.073 18.9945 128.914 18.6892 127.185 18.4478C126.498 18.3518 126.184 18.3793 125.188 18.3142C120.875 18.0325 117.935 17.5576 116.365 16.8896C115.306 16.4387 114.333 15.8881 113.583 14.7638C112.725 13.4773 112.473 11.4978 113.045 9.72795C113.478 8.43251 114.309 7.30415 115.42 6.50063C116.774 5.50037 118.187 5.24293 120.127 5.04868C124.165 4.64439 136.352 5.31522 132.303 5.04868L135.874 7.02425V7.95258C135.861 7.95246 134.67 7.94297 132.942 7.93824C131.95 7.93553 128.807 7.92754 126.783 7.95258C120.565 8.02949 117.438 8.0947 116.556 9.40454C115.835 10.4737 115.892 12.0811 116.556 13.1151C117.529 14.6314 119.527 14.4795 124.186 15.051C129.873 15.7486 132.75 16.1359 134.438 18.0248C136.24 20.0417 136.205 22.7461 136.199 23.2787C136.193 23.6923 136.115 26.6678 134.088 28.7638C132.201 30.716 129.665 30.8287 120.29 30.8611C118.231 30.8683 115.214 30.8737 111.523 30.8612Z"
        fill="white"
      />
      <path
        d="M137.737 31.0629V7.97442H126.703V4.97314H152.076V7.97442H141.019V31.0629H137.737Z"
        fill="white"
      />
      <path
        d="M180.445 19.6019C182.315 19.2712 183.821 18.4323 184.963 17.0853C186.104 15.7382 186.675 14.1189 186.675 12.2276C186.675 9.95936 185.946 8.18312 184.487 6.89894C183.028 5.61478 181.014 4.97267 178.446 4.97266L162.5 5.04849C162.798 6.02399 163.096 6.99949 163.394 7.975H178.399C179.937 7.975 181.13 8.3568 181.979 9.12041C182.826 9.88509 183.25 10.9526 183.25 12.3228C183.25 13.7247 182.826 14.7879 181.979 15.5122C181.13 16.2382 179.881 16.6006 178.232 16.5996H166.653V19.6487H176.829L183.393 31.0626H187.008L180.445 19.6019Z"
        fill="white"
      />
      <path
        d="M13.88 25.6992C15.449 25.6992 16.7209 24.4352 16.7209 22.876C16.7209 21.3167 15.449 20.0527 13.88 20.0527C12.311 20.0527 11.0391 21.3167 11.0391 22.876C11.0391 24.4352 12.311 25.6992 13.88 25.6992Z"
        fill="#28D3CF"
      />
      <path
        d="M249.877 27.5621C249.876 27.7454 249.635 27.8119 249.54 27.6555C244.025 18.612 238.51 9.56848 232.996 0.524993C232.902 0.370168 233.067 0.187526 233.23 0.26596L250.036 8.32805C249.983 14.7394 249.93 21.1508 249.877 27.5621Z"
        fill="white"
      />
      <path
        d="M229.104 0.387C229.268 0.305574 229.436 0.488858 229.341 0.644864C223.826 9.68834 218.312 18.7318 212.797 27.7753C212.701 27.9332 212.458 27.8636 212.46 27.6787L212.647 8.57893L229.104 0.387Z"
        fill="white"
      />
      <path
        d="M249.315 30.8525C249.508 30.8517 249.569 31.1139 249.395 31.1985L231.258 39.9999L213.099 31.3498C212.925 31.2665 212.983 31.0041 213.177 31.0033L216.595 30.989L216.604 30.9731L245.878 30.851L245.887 30.8668L249.315 30.8525Z"
        fill="white"
      />
      <path
        d="M240.462 19.8574H221.789L231.087 4.5708L240.462 19.8574Z"
        fill="#28D3CF"
      />
      <path
        d="M245.044 27.3572L217.113 27.5403L219.846 23.0192L221.926 19.5513H240.334L242.351 23.0192L245.044 27.3572Z"
        fill="#28D3CF"
      />
      <path
        d="M231.04 21.3396C232.609 21.3396 233.881 20.0324 233.881 18.4198C233.881 16.8072 232.609 15.5 231.04 15.5C229.471 15.5 228.199 16.8072 228.199 18.4198C228.199 20.0324 229.471 21.3396 231.04 21.3396Z"
        fill="white"
      />
      <path
        d="M69.2393 25.6992C70.8083 25.6992 72.0802 24.4352 72.0802 22.876C72.0802 21.3167 70.8083 20.0527 69.2393 20.0527C67.6704 20.0527 66.3984 21.3167 66.3984 22.876C66.3984 24.4352 67.6704 25.6992 69.2393 25.6992Z"
        fill="#28D3CF"
      />
      <path
        d="M161.442 25.6992C163.011 25.6992 164.283 24.4352 164.283 22.876C164.283 21.3167 163.011 20.0527 161.442 20.0527C159.873 20.0527 158.602 21.3167 158.602 22.876C158.602 24.4352 159.873 25.6992 161.442 25.6992Z"
        fill="#28D3CF"
      />
    </svg>
  );
};

export { Logo }
