import React from "react";
import { translations } from "../../../../../i18n";
import { useTranslation } from "react-i18next";
import { labels, indicators } from "./content";
import classes from "./HowWorks.module.scss";
import img1 from "../../../../../Media/Common/tab1.png";

const TabConsult = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.tabContent}>
      <h4
        dangerouslySetInnerHTML={{
          __html: t(translations.howWorks.title1),
        }}
      />

      <div className={classes.wrapper}>
        <div className={classes.tabLeftColumn}>
          <p>{t(translations.howWorks.description1)}</p>
          <p>{t(translations.howWorks.description2)}</p>
          <img alt="tab" src={img1} />
        </div>

        <div className={classes.tabRightColumn}>
          <div className={classes.tabIndicators}>
            {indicators.map((item) => {
              return (
                <div
                  key={`indicator${item.id}`}
                  className={classes.indicatorItem}
                >
                  <h3>{t(item.value)}</h3>
                  <h4>{t(item.title)}</h4>
                </div>
              );
            })}
          </div>
          <div className={classes.tabLabels}>
            {labels.map((item) => {
              return (
                <div key={`labels${item.id}`} className={classes.labelItem}>
                  {item.content}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export { TabConsult }
