import React from "react";
import logo_icon from "../../../../../Media/Icons/logo_icon.png";
import { translations } from "../../../../../i18n";
import classes from "./Benefits.module.scss";
import { t } from "i18next";
import { elementPartlyVisible } from "../../../../../Utils";


interface State {
  visible: boolean;
}

export class Benefits extends React.Component<{}, State> {
  benefits_block: React.RefObject<any>;

  constructor(props: any) {
    super(props)
    this.state = {
      visible: false
    }

    this.benefits_block = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("scroll", this.onScroll, true);
    if (this.isAnchorVisible()) {
      this.setState({ visible: true });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.onScroll, true);
  }

  onScroll = () => {
    if (this.isAnchorVisible()) {
      this.setState({ visible: true });
    }
  }

  isAnchorVisible = () => {
    const { current } = this.benefits_block;
    return current && elementPartlyVisible(current);
  }

  render() {
    return (
      <div
        className={`${classes.benefits_block} ${this.state.visible ? classes.visible : classes.invisible}`}
        ref={this.benefits_block}
      >
        <h2>{t(translations.benefits.mainTitle)}</h2>
        <p>{t(translations.benefits.mainDescription)}</p>
        <div className={classes.benefits}>
          <div className={classes.confidential}>
            <h3>{t(translations.benefits.title1)}</h3>
            <p>{t(translations.benefits.desc1)}</p>
          </div>

          <div className={classes.personalization}>
            <p>{t(translations.benefits.desc2)}</p>
            <h3>{t(translations.benefits.title2)}</h3>
          </div>

          <div className={classes.spec}>
            <h3>{t(translations.benefits.title3)}</h3>
            <p>{t(translations.benefits.desc3)}</p>
          </div>

          <div className={classes.exlusive}>
            <h3>{t(translations.benefits.title4)}</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: t(translations.benefits.desc4),
              }} />
          </div>

          <div className={classes.logo_container}>
            <div className={classes.logo}>
              <img alt={"benefits"} src={logo_icon} />
            </div>
          </div>
        </div>
      </div>
    );
  }
};