export const content = [
  {
    id: 0,
    img: "https://storage.yandexcloud.net/dev-adabstar/users/b3a7d402-4f97-427d-a1dd-a5a42092d33b.jpg",
    name: "Евгения Романова",
    position: "Software Development Manager",
    instagram: "http://yandex.ru",
    labels: ["Карьерная консультация", "Карьерная развилка", "Смена сферы"],
    text: <span>«Изначально хочу сказать, что консультация, это не только про работу, но и про жизнь в целом, оценка своих возможностей и желаний. <br/>1. Я поняла, что плавный переход – это нормально. Не нужно спешить и рвать волосы на голове, если предложений пока нет. Есть время на спокойное взвешивание и дальнейший поиск. <br/>2. Теперь я знаю и четко определяю свою ценность, как сотрудника. <br/>3. С помощью консультации, я смогла сформировать классное резюме (до сих пор восхищаюсь им). <br/>4. Выделяю свои достижения, радуюсь даже маленьким победам. <br/>5. Я стала более уверенной в себе, в своих возможностях и своих силах. Потому как знаю, чего я стою и какая у меня карьерная миссия. <br/>Спасибо еще раз за уделенное время и прояснение своей текущей карьерной ситуации»</span>,
  },
  {
    id: 1,
    img: "https://storage.yandexcloud.net/dev-adabstar/users/ce467f3d-3e55-4145-882a-435c6a8155de.jpg",
    name: "Лев Наумов",
    position: "Software Development Manager",
    instagram: "http://yandex.ru",
    labels: ["Поиск работы", "Резюме"],
    text: "«Хочу поделится промежуточными результатами: в компанию мечты мое резюме направили напрямую начальнице департамента, она правда в отпуске, но ответила, что хочет со мной пообщаться. Жду. Также мне звонили с одной финансовой компании, у них вакансия эксперта, пригласили на собеседование на следующей неделе. Они были моим следующим вариантом, если в ту компанию не попаду. Очень рад такому результату! Учитывая пару проведенных консультаций. Еще раз благодарю за помощь в составлении резюме, которое ведет меня именно туда, куда я хочу!»",
  },

  {
    id: 2,
    img: "https://storage.yandexcloud.net/dev-adabstar/users/3d92689c-258f-4d78-96c0-40e9e009d306.jpg",
    name: "Оля Щербакова",
    position: "Software Development Manager",
    instagram: "http://yandex.ru",
    labels: ["Профориентация взрослых", "Самоопределение"],
    text: "«Обратиться к карьерному консультанту можно не только при поиске работы, но и в случае типирования личности. Это позволяет по – новому взглянуть на свои сильные/слабые стороны, понять, над чем нужно поработать, а к чему вообще не стоит притягиваться, в том числе проанализировать свой подход к работе и сделать её эффективнее. Благодаря таким разборам проще проводить параллели между моими успехами/неудачами на работе и моим внутренним состояние, что для меня имеет важное значение! Спасибо!»",
  },
  {
    id: 3,
    img: "https://storage.yandexcloud.net/dev-adabstar/users/76a216d5-5342-4805-b436-ee6fefbc6bab.jpg",
    name: "Влада Шашкова",
    position: "Software Development Manager",
    instagram: "http://yandex.ru",
    labels: ["Оценка компетенций", "Развитие карьеры"],
    text: "«Огромное спасибо за четкий вектор моего развития. На ошибки мне указали мягко, корректно, не было желания «закрываться» и оправдываться. Разобрали мои сильные стороны, от которых я отмахивалась и не придавала значение. В разговоре не был сопротивления, словно это я и сама все знала, просто мне это вслух проговорили и стал легче, от того, что я понимаю «куда» двигаться, не было только видения «как». Сейчас есть. Еще раз спасибо!!!»",
  },
  {
    id: 4,
    img: "https://storage.yandexcloud.net/dev-adabstar/users/e987c0a0-0218-4be6-8747-05e1a47e35aa.jpg",
    name: "Антон Беляев",
    position: "Software Development Manager",
    instagram: "http://yandex.ru",
    labels: ["Анализ рынка", "Карьерная развилка"],
    text: "«Благодарю за отлично проведенную консультацию! Профессионально и по делу. Мы разобрали и четко сформулировали запрос, держали фокус на нем на протяжении всей консультации, что позволило мне углубиться в свою задачу и не отвлекаться на частности. В итоге я проанализировал две сферы, возможно для следующего шага в карьере, понял, насколько полно я могу реализовать свои ценности в каждой из них и сделал выбор. Особенно понравилось, что выбранный мною специалист обладает экспертизой в подборе персонала и смог взглянуть на ситуацию, как практикующий HR-менеджер. Спасибо за подход и желание помочь!»",
  },
  {
    id: 5,
    img: "https://storage.yandexcloud.net/dev-adabstar/users/67aaa7cc-2dcb-4689-8234-d8679cdaed22.jpg",
    name: "Карина Свиридова",
    position: "Software Development Manager",
    instagram: "http://yandex.ru",
    labels: ["Выгорание"],
    text: "«В последние месяцы перед новым годом я стала замечать, что мне не хватает сил ни на что. Я была буквально усыпана делами – своими рабочими, от коллег, личными. Тогда я поняла, что я нахожусь в состоянии выгорания. Обратившись к карьерному психологу, мы стали разбирать, откуда взялась усталость, почему мое эмоциональное состояние сказывается на реализации дел и как вернуть себя в ресурс. За несколько пройденных консультаций, я пришла к тому, как прекратить брать на себя ответственность, как реализовать свой отдых и делать упор на себе! Буду продолжать учиться и дальше заботиться о себе, и не входить в это огненное состояние. Спасибо!»",
  },
];
