import React from "react";
import { Carousel, Progress as AntProgress } from "antd";
import { content } from "./content";
import { translations } from "../../../../../i18n";
import { t } from "i18next";
import classes from "./Services.module.scss";
import "./slider.css";
import { CarouselRef } from "antd/lib/carousel";
import { elementPartlyVisible } from "../../../../../Utils";


interface Props {
    title: string;
    description: string;
    url: string;
}


const SlideItem: React.FC<Props> = ({ title, description, url }) => {
    return (
        <div className={classes.sliderItem}>
            <h3>{title}</h3>
            <p>{description}</p>
            {/* <a href={url}>
                {
                    t(translations.servicesLinkLabel)
                }
            </a> */}
        </div>
    );
};


interface Arrow {
    onClick: any;
    arrowType: string;
    active: boolean;
}


const Arrow: React.FC<Arrow> = ({ onClick, arrowType, active }) => {
    return (
        <>
            <div
                className={`${arrowType} ${active ? classes.active : classes.inactive}`}
                onClick={onClick}
            />
        </>
    )
}


class Services extends React.Component<{}, { current_slide: number, scroll_count: number, slide_show: number, visible: boolean }> {
    sliderRef: React.RefObject<CarouselRef>;
    services_block: React.RefObject<any>;

    constructor(props: Props) {
        super(props)
        this.state = {
            current_slide: 3,
            scroll_count: 1,
            slide_show: 1,
            visible: false
        }

        this.sliderRef = React.createRef();
        this.services_block = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("scroll", this.onScroll, true);
        if (this.isAnchorVisible()) {
            this.setState({ visible: true });
          }
        
        const slideShow = window.innerWidth > 1080 ? 3 : 1.3;
        this.setState({ slide_show: slideShow });
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.onScroll, true);
    }

    onScroll = () => {
        if (this.isAnchorVisible()) {
            this.setState({ visible: true });
        }
    }

    isAnchorVisible = () => {
        const { current } = this.services_block;
        return current && elementPartlyVisible(current);
    }

    onChange = (from: number, to: number) => {
        if (from !== to) {
            if (from < to) {
                if (this.state.current_slide !== content.length) {
                    this.setState({ current_slide: this.state.current_slide + this.state.scroll_count });
                }
            } else {
                if (this.state.current_slide !== this.state.slide_show) {
                    this.setState({ current_slide: this.state.current_slide - this.state.scroll_count });
                }
            }
        }
    };

    render() {
        const settings = {
            slidesToScroll: this.state.scroll_count,
            slidesToShow: this.state.slide_show,
            arrows: true,
            draggable: true,
            prevArrow: (
                <Arrow
                    active={this.state.current_slide === this.state.slide_show ? false : true}
                    onClick={onclick}
                    arrowType={classes.servicesLeftArrow}
                />
            ),
            nextArrow: (
                <Arrow
                    active={this.state.current_slide === content.length ? false : true}
                    onClick={onclick}
                    arrowType={classes.servicesRightArrow}
                />
            ),
        };

        return (
            <div
                className={`${classes.services} ${this.state.visible ? classes.visible : classes.invisible}`}
                ref={this.services_block}
            >
                <h3 className={classes.servicesTitle}>
                    {
                        t(translations.servicesTitle)
                    }
                </h3>
                <span className={classes.indicator}>
                    <span className={classes.colored}>{this.state.current_slide} </span> / {content.length}
                </span>
                <AntProgress
                    className={classes.progress}
                    type="line"
                    percent={(this.state.current_slide * 100) / content.length}
                    showInfo={false}
                    strokeColor="#23232B"
                    strokeWidth={2}
                />
                <Carousel
                    beforeChange={this.onChange}
                    infinite={false}
                    {...settings}
                    className={classes.servicesSlider}
                    ref={this.sliderRef}
                >
                    {content.map((item) => {
                        return (
                            <SlideItem
                                key={item.id}
                                title={item.title}
                                description={item.description}
                                url={item.url}
                            />
                        );
                    })}
                </Carousel>
            </div>
        )
    }
}


export { Services };
