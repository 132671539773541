import React from "react";
import { MainView } from "../Components/Layout/Common/MainView";
import { WelcomeScreen } from "../Components/Layout/Marketing/Main/WelcomeScreen";
import { Benefits } from "../Components/Layout/Marketing/Main/Benefits";
import { Services } from "../Components/Layout/Marketing/Main/Services";
import { HowWorks } from "../Components/Layout/Marketing/Main/HowWorks";
import { Case } from "../Components/Layout/Marketing/Main/Case";

export class Main extends React.Component {
    render() {
        return (
            <MainView>
                <div className="wrapper">
                    <WelcomeScreen />
                    <Benefits />
                    <Services />
                    <HowWorks />
                    <Case />
                </div>
            </MainView>
        );
    }
}
