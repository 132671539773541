import React from "react";
import { translations } from "../../../../../i18n";
import { TabConsult } from "./TabConsult";
import { TabSolo } from "./TabSolo";
import { t } from "i18next";
import classes from "./HowWorks.module.scss";
import { Secondary2Button } from "../../../../Buttons";
import { elementPartlyVisible } from "../../../../../Utils";

enum TabsTypes {
  Сonsultant = "Consultant",
  Solo = "Solo",
}

interface Props {
  tab: TabsTypes;
}

interface State {
  active_tab: string;
  visible: boolean;
}

class HowWorks extends React.Component<{}, State> {
  howwork_block: React.RefObject<any>;
  constructor(props: Props) {
    super(props)
    this.state = {
      active_tab: TabsTypes.Сonsultant,
      visible: false
    }

    this.howwork_block = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("scroll", this.onScroll, true);
    if (this.isAnchorVisible()) {
      this.setState({ visible: true });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.onScroll, true);
  }

  onScroll = () => {
    if (this.isAnchorVisible()) {
      this.setState({ visible: true });
    }
  }

  isAnchorVisible = () => {
    const { current } = this.howwork_block;
    return current && elementPartlyVisible(current);
  }

  render() {
    return (
      <div
        className={`${classes.howWorks} ${this.state.visible ? classes.visible : classes.invisible}`}
        ref={this.howwork_block}
      >
        <h3 className={classes.howWorksTitle}>
          {
            t(translations.howWorks.heading)
          }
        </h3>
        <div className={classes.howWorksTabs}>
          <div
            style={{ width: "50%", height: "80px" }}
          >
            <Secondary2Button
              onClick={() => this.setState({ active_tab: TabsTypes.Сonsultant })}
              class={this.state.active_tab === TabsTypes.Сonsultant ? 'active' : 'inactive'}
            >
              {
                t(translations.howWorks.tab1)
              }
            </Secondary2Button>
          </div>
          <div
            style={{ width: "50%", height: "80px" }}
          >
            <Secondary2Button
              onClick={() => this.setState({ active_tab: TabsTypes.Solo })}
              class={this.state.active_tab === TabsTypes.Solo ? 'active' : 'inactive'}
            >
              {
                t(translations.howWorks.tab2)
              }
            </Secondary2Button>
          </div>
        </div>
        {
          this.state.active_tab === TabsTypes.Сonsultant &&
          <TabConsult />
        }
        {
          this.state.active_tab === TabsTypes.Solo &&
          <TabSolo />
        }
      </div>
    )
  }
}


export { HowWorks };
