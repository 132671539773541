import React from "react";
import { TopMenu } from "../../Components/Layout/Common/TopMenu";
import {MenuType} from "../../Components/Layout/Common/TopMenu/types";
import { Footer } from "../../Components/Layout/Common/Footer";

export const Terms = () => {
  return (
    <>
      <div className="wrapper" style={{marginBottom: "50px"}}>
      <h1>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
      </h1>
<h2>ОПРЕДЕЛЕНИЯ И ИНТЕРПРЕТАЦИЯ
</h2>

В настоящей политике конфиденциальности используются следующие определения:
Персональные данные – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
Cookie - небольшой текстовый файл, размещаемый на вашем компьютере этой платформой, когда вы посещаете определенные части платформы и/или когда вы используете определенные функции платформы. 
Платформа - веб-сайт, который вы используете в настоящее время, https://adabstar.ru и любые поддомены этого сайта, если это прямо не исключено их собственными условиями.

<h2>ОБЩИЕ ПОЛОЖЕНИЯ
</h2>

Настоящая политика в отношении обработки персональных данных (далее – «Политика») разработана в соответствии с п. 2 ст. 18.1 Федерального закона от 27.07.2006 №152-ФЗ «О персональных данных» (далее – Федеральный закон от 27.07.2006 №152-ФЗ) и действует в отношении всех персональных данных, которые ИП Кузнецова Александра Дмитриевна (ИНН 110214124443) (далее «Владелец Платформы») получает от субъектов персональных данных – юридических и физических лиц, индивидуальных предпринимателей и самозанятых граждан (далее – «Субъект персональных данных»), в связи с предоставлением им доступа к онлайн платформе (программному комплексу) «Adabstar», размещенному в информационно-телекоммуникационной сети «Интернет» по адресу: https://adabstar.ru (далее – «Сервис ADABSTAR»). 
Все встречающиеся далее по тексту выражения «мы», «нас» или «наш» должны толковаться как принадлежащие ИП Кузнецова Александра Дмитриевна и Сервису ADABSTAR.
Настоящая политика конфиденциальности применяется к использованию нами любых данных, собранных нами или предоставленных вами в связи с использованием вами Веб-сайта.

<h3>	•	ПОНЯТИЯ “КОНТРОЛЕР” И “ПРОЦЕССОР”
</h3>
<p>Контролер – Сервис ADABSTAR, обрабатывающий Ваши персональные данные, как клиента Сервиса, которые вы предоставляете нам при регистрации на Сервисе или с помощью других способов передачи данных."
</p>
<p>Процессор – Сервис ADABSTAR, получающий от клиентов Сервиса - юридических и физических лиц, а также индивидуальных предпринимателей   персональные данные клиентов Сервиса.
</p>

<h3>	•	КОГДА МЫ ДЕЙСТВУЕМ КАК КОНТРОЛЕР И КАК ПРОЦЕССОР
</h3>

Сервис ADABSTAR будет действовать как «Контролер» персональных данных, которые вы предоставляете нам при регистрации, или с помощью других способов передачи данных. Вам не нужно предоставлять персональные данные для просмотра общедоступных страниц нашей платформы.

Если вы являетесь сотрудником или уполномоченным представителем одного из наших клиентов, который заключил с нами Пользовательское соглашение о предоставлении услуг, мы будем действовать как «Процессор» ваших персональных данных, переданных нашим клиентом. Наш клиент будет действовать как Контролер вашей информации. В качестве Процессора мы не владеем, не контролируем и не используем какие-либо данные, предоставленные нам нашими клиентами, которые действуют как Контролеры.

<h2>ПЕРСОНАЛЬНАЯ ИДЕНТИФИЦИРУЕМАЯ ИНФОРМАЦИЯ
</h2>

«Персональная идентифицируемая информация» (или «ПИИ») относится к контактной информации, которая прямо или косвенно определяет конкретно вас, например: 
<ul>
	
  <li>ваше имя, фамилия, отчество;
	</li>
  <li>пол;
	</li>
  <li>дата рождения (число, месяц, год);
	</li>
  <li>номер телефона;
	</li>
  <li>страна и город; 
	</li>
  <li>адрес электронной почты;
	</li>
  <li>данные об образовании;
	</li>
  <li>специализации работы;
	</li>
  <li>IP-адрес (собирается автоматически);
	</li>
  <li>тип и версия веб-браузера (собирается автоматически);
	</li>
  <li>операционная система (собирается автоматически);
	</li>
  <li>список URL-адресов, начинающихся с ссылающегося сайта, вашей активности на этом Веб-сайте и сайта, на который вы выходите (собирается автоматически);
	</li>
  <li>изображение профиля;
</li>
</ul>
или любую другую информацию, которая может быть легко связана с вами. 
<p>
Мы не собираем конфиденциальную информацию, такую как информация GPS, расовое или этническое происхождение, политические взгляды, религиозные или философские убеждения, или членство в профсоюзах, генетические или биометрические данные, данные о здоровье или о сексуальной жизни физического лица.
</p>

<h3>	•	ПАССИВНО ИЛИ АВТОМАТИЧЕСКИ СОБИРАЕМЫЕ ДАННЫЕ
</h3>
Пассивно или автоматически собираемые данные (далее «Данные об Использовании») собираются при использовании платформы, например:
<ul>
<li>мы автоматически собираем некоторую информацию о вашем посещении платформы. Эта информация помогает нам улучшать содержание и навигацию платформы и включает ваш IP-адрес, дату, время и частоту доступа к платформе, а также то, как вы используете ее содержимое и взаимодействуете с ней.
</li>
<li>мы будем собирать ваши Данные автоматически с помощью файлов cookie в соответствии с настройками файлов cookie в вашем браузере. Для получения дополнительной информации о файлах cookie и о том, как мы их используем на Веб-сайте, см. раздел 8, озаглавленный «Данные cookie и процедуры отказа».
</li>

</ul>
Мы, наши аффилированные лица и наши партнеры по маркетингу, можем использовать автоматизированные средства для сбора различных типов информации о вас, вашем компьютере или другом устройстве, используемом для доступа к платформе, ее службам, связанных с ней веб-приложениям и загружаемому программному обеспечению. Эта информация основана на использовании вами платформы и может быть получена при посещении вами других веб-сайтов. Данные об Использовании по сути являются анонимными, но могут косвенно использоваться для идентификации человека.

<h3>	•	ДЛЯ ЧЕГО НАМ НУЖНЫ ВАШИ ДАННЫЕ
</h3>
Нам необходимо знать вашу ПИИ, которую вы предоставляете нам, когда мы выступаем в качестве Контролера, для того чтобы мы могли ответить на ваш запрос во время регистрации на Сервисе, получении от нас технической поддержки или любой иной обратной связи. Кроме того, нам необходимо знать вашу ПИИ для отправки вам электронных писем и для других целей, например для информационной рассылки о проведении технических работ на Сервисе. В том числе и для обмена ПИИ, как описано в разделе «Обмен информацией и раскрытие информации» ниже. Нам также необходимо знать ПИИ пользователей, являющихся уполномоченными представителями наших клиентов, когда мы действуем в качестве Процессора, в целях регулирования использования наших услуг авторизованным пользователем. Нам необходимо знать вашу «Дополнительную персональную идентифицируемую информацию» (или – ДПИИ) для того, чтобы мы могли полностью идентифицировать и верифицировать наших авторизованных пользователей с целью исключения недобросовестных действий, мошенничества, для повышения доверия пользователей к Сервису.

<h3>	•	КАК МЫ СОБИРАЕМ И ИСПОЛЬЗУЕМ ВАШИ ДАННЫЕ
</h3>

Сервис ADABSTAR собирает ПИИ непосредственно у вас, когда вы:
<ul>
<li>связываетесь с нами через платформу, электронную почту или любым другим способом;
</li>
<li>когда вы регистрируетесь у нас и создаете учетную запись для получения наших продуктов/услуг;
</li>
<li>когда вы заполняете опросы, которые мы используем в исследовательских целях (хотя вы не обязаны отвечать на них);
</li>
<li>когда вы участвуете в конкурсе или рекламной акции через канал социальных сетей;
</li>
<li>когда вы делаете платежи нам через нашу платформу или иным образом;
</li>
<li>когда вы решите получать от нас маркетинговые сообщения;
</li>
<li>когда вы пользуетесь нашими услугами;
</li>

</ul>
а также, в любом ином случае, при взаимодействии с нами на наших веб-страницах или по другим способам обратной связи. 

<p>Когда вы регистрируетесь в сервисе ADABSTAR, вы соглашаетесь на сбор вашей ПИИ, а также соглашаетесь на отправку вам электронных писем, включая использование ПИИ для рекламных рассылок, нацеленных на продвижение наших услуг. Вы можете отозвать свое согласие в любое время, отменив подписку на наши электронные письма, через обратную связь с нами по электронной почте support@adabstar.ru, указав в теме письма «Отказ от рассылки» для организации отмены автоматической рассылки.
</p>
<p>Сервис ADABSTAR использует сторонние приложения для добавления информации в данные, которые мы собираем у вас. Это усовершенствование данных позволяет нам предоставлять более полезную персонализированную связь. Мы собираем данные об использовании, как описано выше, и данные cookie. Для получения дополнительной информации и того, как настроить браузер для отказа от приема файлов cookie, см. раздел «Данные cookie и процедуры отказа» ниже.
</p>
<p>Мы можем неограниченно применять Данные об Использовании для любых целей, в том числе без ограничений для настройки ремаркетинга, предложений и контента, показываемых вам на основе ваших посещений и/или использования платформы или услуг, для анализа эффективности рекламы, предложений и контента, а также вашего взаимодействия с ними.
</p>
<p>С учетом вашего права на отказ, мы можем комбинировать Данные об Использовании с ПИИ, которые мы собираем, и использовать комбинированные данные для создания профилей о вас исключительно в целях усовершенствования наших продуктов и услуг и улучшения вашего пользовательского опыта в нашем сервисе.
</p>
<h3>	•	ДАННЫЕ COOKIE И ПРОЦЕДУРЫ ОТКАЗА
</h3>

Сервис ADABSTAR может автоматически собирать Данные об Использовании с помощью файлов «cookie». Cookie — это небольшие текстовые файлы, которые веб-сайт/онлайн-платформа использует для распознавания повторных заходов пользователей, для обеспечения постоянного доступа пользователя к сайту и облегчения использования сайта. Cookie также позволяют сайту отслеживать поведение пользователей и накапливать информацию об использовании сайта, которая позволит улучшить контент.

<h3>	•	КАК ДОЛГО МЫ ХРАНИМ ВАШИ ДАННЫЕ
</h3>

Мы не будем сохранять вашу ПИИ дольше, чем требуется. Это означает, что мы сохраним вашу ПИИ для любого периода времени, описанного ниже (или их комбинации):
<ul>
<li>до тех пор, пока это требуется законом,
</li>
<li>до тех пор, пока у нас не останется веской причины для ее сохранения,
</li>
<li>пока вы не попросите нас прекратить ее использовать,
</li>
<li>или до тех пор, пока наш клиент, для которого вы, как наш пользователь, являетесь уполномоченным представителем, остается клиентом.
</li>

</ul>
Когда мы удалим вашу ПИИ из наших баз данных, она останется в нашей резервной системе, пока та не очистится.

<h3>	•	ОБМЕН ИНФОРМАЦИЕЙ И РАСКРЫТИЕ ИНФОРМАЦИИ
</h3>
<ul>

<li>Общая политика раскрытия информации: мы можем делиться и раскрывать вашу ПИИ, как описано ниже. Мы можем раскрывать и делиться Данными об Использовании без ограничений, в том числе в ситуациях, описанных ниже. Пожалуйста, свяжитесь с нами по адресу support@adabstar.ru , для отказа от обмена вашей информацией с третьими лицами.
</li>
<li>Аффилированные лица: мы можем предоставить вашу ПИИ и Данные об Использовании любым аффилированным компаниям, которые у нас могут быть, включая наши дочерние компании. Аффилированные лица — это компании, которые мы юридически контролируем или они контролируют нас.
</li>
<li>Подпроцессор: мы можем предоставить доступ к вашей ПИИ и Данным об Использовании нашим доверенным Подпроцессорам, которые помогают нам в настройке и обслуживании платформы. Например, мы можем заключать договоры с третьими лицами для обработки платежей, размещения серверов, обеспечения безопасности и предоставления услуг по созданию, поддержке, оптимизации, аналитике, отчетности и техническому обслуживанию и разработке программного обеспечения. Кроме того, нашим Подпроцессорам будет предоставлен доступ к вашей информации только в том случае, если это в самом деле необходимо для предоставления услуг, указанных в договорах.
</li>
<li>Наследник: если мы продаем или иным образом передаем часть или весь наш бизнес или активы другой организации (например, в ходе приобретения, слияния, банкротства или ликвидации), мы можем перенести вашу ПИИ и Данные об Использовании. В таком случае мы обязуемся сделать так, чтобы покупатель или получатель согласились с обязательствами, изложенными в настоящей Политике.
</li>
<li>Принудительные обязательства. За исключением обмена вашей информацией, как описано в пункте 10 «Обмен информацией и раскрытие информации», сервис ADABSTAR не будет раскрывать информацию о вас или содержании ваших сообщений, если не будет вынужден сделать это в силу обстоятельств, в одном из нижеперечисленных случаев, когда это действительно необходимо:
</li>
<li>для соблюдения закона;
</li>
<li>в случае судебного процесса;
</li>
<li>с целью соблюдения данных правил и условий;
</li>
<li>в случае жалоб на контент, нарушающий права третьих сторон;
</li>
<li>для обеспечения выполнения условий приобретения сервиса ADABSTAR или его слияния с другой компанией;
</li>
<li>для защиты интересов сервиса ADABSTAR в отношении его имущества, включая, помимо прочего, его интеллектуальную собственность.
</li>
<li>Маркетинговые партнеры в целях ремаркетинга. Мы можем поделиться вашей информацией со сторонними сервисами для целей ремаркетинга. Описание ремаркетинга и прав на отказ вы найдете в разделе «Ремаркетинг» ниже.
</li>
</ul>

<h2>РЕМАРКЕТИНГ
</h2>
<p>Сервис ADABSTAR использует сторонние коды отслеживания cookie и пиксели, включая код отслеживания Google Ads, Yandex и трекинг-код Google Tag Manager.
</p>

<p>Это означает, что мы продолжим показывать вам рекламу через Интернет, в частности, в контентной сети Google и Yandex. Сторонние поставщики, включая Google, будут размещать файлы cookie в веб-браузерах, чтобы показывать рекламу на основе ваших прошлых посещений нашей платформы. Это позволяет нам делать специальные предложения и продолжать продавать услуги тем, кто проявил к ним интерес. 
</p>

<p>Вы можете отказаться от использования файлов cookie Google, посетив Менеджер настроек объявлений. Вы можете отключить ремаркетинг в Яндексе на странице настроек объявлений. 
</p>

<h2>АНАЛИТИКА
</h2>
<p>
Мы можем подключать сторонние сервисы по аналитике для мониторинга и анализа веб-трафика и отслеживания поведения пользователей на нашей платформе.
</p>
<h4>
Google Analytics (Google).</h4>
Google Analytics - это служба веб-анализа, представляемая Google Inc. («Google»). Google использует собранные данные для отслеживания и изучения использования платформы пользователями, для подготовки отчетов об активности платформы и для обмена этими данными с другими службами Google. 
<p>
Собранная информация: 
</p>
<ul>
<li>Сookie и Данные об использовании. 
</li>
<li>Ознакомится с Политикой конфиденциальности Google Analytics вы можете на странице https://policies.google.com/?hl=ru. 
</li>
<li>Отказаться от Google Analytics с помощью надстройки браузера Google, вы можете на странице https://tools.google.com/dlpage/gaoptout.
</li>

</ul>
<h4>Яндекс Метрика (Яндекс) - это услуга веб-анализа, предоставляемая Yandex N.V. («Яндекс»).</h4> 
<p>
Яндекс использует собранные данные для отслеживания и изучения использования платформы, подготовки отчетов о ее деятельности и обмена ими с другими службами Яндекс.
</p>
<p>Собранная информация:</p>
<ul>

<li>Сookie и Данные об Использовании. 
</li>
<li>Ознакомится с Политикой конфиденциальности Яндекс вы можете на странице https://yandex.ru/legal/confidential/. 
</li>
<li>Отказаться от передачи данных в Яндекс Метрику, установив Блокировщик от Яндекс Метрики.
</li>
</ul>

<h2>ВАШИ ПРАВА
</h2>

Вы можете связаться с нами, чтобы узнать, обрабатываем ли мы вашу ПИИ. Если да, то вы можете спросить нас, какую Персональную идентификационную информацию мы храним и что мы делаем с вашими данными. Если нужно, мы можем предоставить вам (или, если это технически возможно, указанной вами третьей стороне) копию любой ПИИ, которую храним о вас, за плату, установленную законом (не превышающую стоимость воспроизведения этих документов).
Мы можем отклонить запрос на доступ или исправление ПИИ в обстоятельствах, предусмотренных законом. И, если мы это сделаем, мы предоставим вам письменное уведомление с причинами отказа.
Кроме того, вы можете:
<ul>
<li>запросить доступ и исправление или удаление вашей ПИИ,
</li>
<li>запросить ограничение на обработку вашего ПИИ,
</li>
<li>и отозвать свое согласие в любое время.
</li>

 </ul>
Если вы направите нам запрос о применении каких-либо из вышеуказанных прав, при необходимости, мы направим уведомление о вашем запросе другим уполномоченным сторонам, которые хранят и обрабатывают вашу ПИИ.

<h2>ССЫЛКИ НА ДРУГИЕ САЙТЫ
</h2>

Наша платформа может содержать ссылки на другие веб-сайты. Если вы нажмете на какие-либо ссылки на веб-сайты или согласитесь с любыми рекламными предложениями, ваша информация, передаваемая в клике и любая информация, которую вы предоставите в процессе регистрации или покупки, будут перенесены на эти сайты. Мы не несем никакой ответственности за политику и действия этих сайтов. Вы должны быть осторожны, и просматривать все политики по охране персональных данных, размещенные на любом из этих сайтов, прежде чем предоставлять им информацию.

<h2>ОНЛАЙН-ПОЛИТИКА В ОТНОШЕНИИ ДЕТЕЙ
</h2>

Мы стремимся к сохранению безопасности персональных данных в Интернете для всех посетителей своей платформы, включая детей. Платформа является общедоступным сайтом. В соответствии с Законом о защите личных сведений детей в Интернете (COPPA) мы не будем сознательно собирать или продавать какую-либо информацию о детях в возрасте до 16 лет. Если вы являетесь родителем или опекуном, который обнаружил, что ваш ребенок в возрасте до 16 лет отправил свою личную информацию без вашего разрешения или согласия, мы удалим информацию из нашего списка по вашему запросу. Чтобы запросить удаление информации о вашем ребенке, пожалуйста, свяжитесь с нашим сайтом через раздел «Служба поддержки» и обязательно укажите в сообщении те же данные для входа, которые были отправлены вашим ребенком.

<h2>ЗАПРОСЫ «НЕ ОТСЛЕЖИВАТЬ»
</h2>

Некоторые веб-браузеры включают функцию «Не отслеживать», которая сигнализирует посещаемым сайтам о том, что вы не хотите, чтобы ваша онлайн-активность отслеживалась. Каждый браузер передает сигналы «Не отслеживать» на веб-сайт по-разному, препятствуя нормальной работе сайта по обработке каждого запроса. Чтобы избежать ошибок между браузерами и нашей платформой, в настоящее время мы не отвечаем на сигналы «Не отслеживать». По мере совершенствования технологии и взаимодействия между браузером и платформой, мы пересмотрим возможность отвечать на сигналы «Не отслеживать» и внесем изменения в нашу политику.

<h2>ЗАПРОСЫ И ЖАЛОБЫ
</h2>
Если у вас есть какие-либо вопросы или жалобы на использование вашей ПИИ, сообщите нам об этом. Вы можете связаться с нами в любое время через раздел «Служба поддержки» ниже, и мы предпримем все необходимое для решения проблемы и ответим вам.

<h3>СВЯЖИТЕСЬ С НАМИ
</h3>
<p>Контактная информация:</p>
<ul>
<li>ИП Кузнецова Александра Дмитриевна 
</li>
<li>ОГРНИП 322470400104375
</li>
<li>ИНН 110214124443
</li>
<li>Почта: support@adabstar.ru
</li>

</ul>
      </div>
    </>
  );
};
