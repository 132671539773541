import { Route, Routes } from "react-router-dom";
import OrderDetails from "./OrderDetails";


const OrderStatus = () => {
    return (
        <Routes>
            <Route path="/" element={<OrderDetails />} />
            <Route path="/:orderId" element={<OrderDetails />} />
        </Routes>
    )
}

export default OrderStatus;