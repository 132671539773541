import React from "react";
import { Paths } from "../../../../Utils";
import api from "../../../../Utils/publicApi";
import "./style.scss";
import { Logo } from "../Logo/Logo";

interface HeaderProps {

}

interface HeaderState {
    menu: any[];
}

export class Header extends React.Component<HeaderProps, HeaderState> {
    constructor(props: any) {
        super(props);

        this.state = {
            menu: [
                {
                    id: 1,
                    title: "Специалисты",
                    url: Paths.Catalog,
                },
                {
                    id: 3,
                    title: "Вход",
                    url: `${process.env.REACT_APP_PA_HOST}`,
                },
            ],
        }
    }

    async componentDidMount() {
        let menu = [...this.state.menu];

        const profile = await api.getProfileShort();

        if (profile && profile.unauthenticated !== true) {
            menu[1] = {
                id: 3,
                title: "Личный кабинет",
                url: `${process.env.REACT_APP_UAF_HOST}/auth/?redirect=${process.env.REACT_APP_PA_HOST}`,
            }
        }

        this.setState({
            menu: menu
        })
    }

    render() {
        return (
            <div className="main-header">
                <div className="main-header-logo">
                    <a href={Paths.Root}>
                        <Logo />
                    </a>
                </div>
                <div className="main-header-control-group">
                    {
                        this.state.menu.map((item: any) => {
                            return (
                                <div
                                    className="main-header-control-group-item"
                                    key={item.id}
                                >
                                    <a
                                        href={item.url}
                                    >
                                        {item.title}
                                    </a>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
};
